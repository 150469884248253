import { ACCOUNT, EVENT_WISHLIST, DOMAIN } from './utils/constants';
var addToWishlist = function addToWishlist() {
  var _window, _window2, _window2$Btg;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (((_window = window) == null ? void 0 : _window.Btg360) && ((_window2 = window) == null ? void 0 : (_window2$Btg = _window2.Btg360) == null ? void 0 : _window2$Btg.add)) {
    window.Btg360.add({
      account: ACCOUNT,
      domain: DOMAIN,
      event: EVENT_WISHLIST,
      items: [{
        active: data == null ? void 0 : data.stateWishList,
        productId: data == null ? void 0 : data.id
      }]
    });
  }
};
export default addToWishlist;
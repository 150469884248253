import _extends from "@babel/runtime/helpers/esm/extends";
var buildChatBotEvent = function buildChatBotEvent(_ref) {
  var pagetype = _ref.pagetype;
  return {
    event: 'clickEvent',
    eventAction: 'impression',
    eventCategory: "".concat(pagetype, ":chatbot-lu"),
    eventLabel: 'chat',
    pagetype: pagetype
  };
};
export var chatbotLuClick = function chatbotLuClick() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    pagetype = _ref2.pagetype;
  return _extends({}, buildChatBotEvent({
    pagetype: pagetype
  }), {
    eventAction: 'click'
  });
};
export var chatbotLuImpression = function chatbotLuImpression() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    pagetype = _ref3.pagetype;
  return buildChatBotEvent({
    pagetype: pagetype
  });
};
var getSearchTerm = function getSearchTerm(pageId) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var route = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var pages = {
    brand: route == null ? void 0 : route.brandId,
    category: route == null ? void 0 : route.categorySlug,
    search: route == null ? void 0 : route.term,
    selection: route == null ? void 0 : route.selectionId,
    seller: route == null ? void 0 : route.sellerId,
    subcategory: route == null ? void 0 : route.slug1
  };
  return (data == null ? void 0 : data.type) || pages[pageId];
};
export default getSearchTerm;
import _extends from "@babel/runtime/helpers/esm/extends";
import { slug } from '@magalu/mixer-utils';
import { buildParamsByPath } from './miniapps';
var eventClick = 'clickEvent';
var buildLabel = function buildLabel(_ref) {
  var _data$questions, _structure$route, _structure$route2;
  var data = _ref.data,
    structure = _ref.structure,
    userId = _ref.userId;
  var logged = userId ? 'logado' : 'nao-logado';
  var questionsLength = (data == null ? void 0 : (_data$questions = data.questions) == null ? void 0 : _data$questions.totalQuestions) || 0;
  var variationId = (structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.productId) || (structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.questionVariationId);
  return "".concat(variationId, "|").concat(logged, "|perguntas:").concat(questionsLength);
};
var eventCategory = 'produto:perguntas';
var eventNonInteraction = 1;
export var baseFloatFormButtonClick = function baseFloatFormButtonClick(_ref2) {
  var _data$product;
  var data = _ref2.data,
    label = _ref2.label,
    userId = _ref2.userId,
    structure = _ref2.structure;
  var productId = (_data$product = data.product) == null ? void 0 : _data$product.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "".concat(slug(label), ":").concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    idUsuario: userId
  }, miniAppKeys);
};
export var clickFloatButtonForm = function clickFloatButtonForm(_ref3, _ref4) {
  var label = _ref3.label,
    userId = _ref3.userId;
  var data = _ref4.data,
    structure = _ref4.structure;
  return _extends({}, baseFloatFormButtonClick({
    data: data,
    label: label,
    structure: structure,
    userId: userId
  }));
};
export var clickMiniappFloatButtonForm = function clickMiniappFloatButtonForm(_ref5, _ref6) {
  var label = _ref5.label,
    userId = _ref5.userId;
  var data = _ref6.data,
    structure = _ref6.structure;
  return _extends({}, baseFloatFormButtonClick({
    data: data,
    label: label,
    structure: structure,
    userId: userId
  }), {
    pageType: 'perguntas',
    screenName: 'webview:perguntas-e-respostas'
  });
};
export var questionClickConfirmQuestion = function questionClickConfirmQuestion(_ref7, _ref8) {
  var _data$product2;
  var userId = _ref7.userId;
  var data = _ref8.data,
    structure = _ref8.structure;
  var productId = (_data$product2 = data.product) == null ? void 0 : _data$product2.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "confirmar:".concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    eventNonInteraction: 0
  }, miniAppKeys);
};
export var questionClickOpenConfirm = function questionClickOpenConfirm(_ref9, _ref10) {
  var _data$product3;
  var label = _ref9.label,
    userId = _ref9.userId;
  var data = _ref10.data,
    structure = _ref10.structure;
  var productId = (_data$product3 = data.product) == null ? void 0 : _data$product3.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "".concat(slug(label), ":").concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    eventNonInteraction: 0
  }, miniAppKeys);
};
export var questionFeedbackResponseError = function questionFeedbackResponseError(_ref11, _ref12) {
  var error = _ref11.error,
    userId = _ref11.userId;
  var structure = _ref12.structure;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: 'erro',
    eventCategory: eventCategory,
    eventLabel: slug(error == null ? void 0 : error.message),
    eventNonInteraction: 1
  }, miniAppKeys);
};
export var questionFormSeeMore = function questionFormSeeMore(_ref13, _ref14) {
  var _data$product4;
  var label = _ref13.label,
    userId = _ref13.userId;
  var data = _ref14.data,
    structure = _ref14.structure;
  var productId = data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : _data$product4.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "".concat(slug(label), ":").concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    eventNonInteraction: 0
  }, miniAppKeys);
};
export var questionListImpression = function questionListImpression(_ref15, _ref16) {
  var _data$product5;
  var userId = _ref15.userId;
  var data = _ref16.data,
    structure = _ref16.structure;
  var productId = (_data$product5 = data.product) == null ? void 0 : _data$product5.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "impressao:".concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    eventNonInteraction: eventNonInteraction
  }, miniAppKeys);
};
var viewItem = function viewItem(data, structure) {
  var _data$product6, _structure$cookies, _structure$cookies$ac;
  var productId = (_data$product6 = data.product) == null ? void 0 : _data$product6.id;
  var userId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id;
  var miniAppKeys = buildParamsByPath(structure.id, userId, structure.site);
  return _extends({
    event: eventClick,
    eventAction: "view_item:".concat(productId),
    eventCategory: eventCategory,
    eventLabel: buildLabel({
      data: data,
      structure: structure,
      userId: userId
    }),
    eventNonInteraction: eventNonInteraction
  }, miniAppKeys);
};
var buildQuestionPageView = function buildQuestionPageView(eventListener) {
  return function (_ref17) {
    var data = _ref17.data,
      structure = _ref17.structure;
    var pageId = structure.id;
    if (pageId === 'questions') {
      return eventListener(data, structure);
    }
    return null;
  };
};
export var questionPageView = buildQuestionPageView(viewItem);
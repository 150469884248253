import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var conditionalToString = function conditionalToString(items) {
  return items ? 'verdadeiro' : 'falso';
};
var slugServicesIds = function slugServicesIds(service) {
  return service ? service == null ? void 0 : service.map(function (item) {
    return String(item == null ? void 0 : item.treeId);
  }).join('|') : 'None';
};
var getMedias = function getMedias(medias) {
  var _medias$videos, _medias$audios, _medias$podcasts;
  var media = [];
  if (medias == null ? void 0 : (_medias$videos = medias.videos) == null ? void 0 : _medias$videos.length) {
    media.push('video');
  }
  if (medias == null ? void 0 : (_medias$audios = medias.audios) == null ? void 0 : _medias$audios.length) {
    media.push('audio');
  }
  if (medias == null ? void 0 : (_medias$podcasts = medias.podcasts) == null ? void 0 : _medias$podcasts.length) {
    media.push('podcasts');
  }
  return media.length ? media.join('|') : 'falso';
};
export var productFormatterOptions = {
  frete: function frete(product) {
    var _product$shipping;
    if (!product.shipping || (product == null ? void 0 : (_product$shipping = product.shipping) == null ? void 0 : _product$shipping.length) === 0) return {};
    var _product$shipping$map = product.shipping.map(function (ship) {
        return ship.packages.map(function (pack) {
          var _pack$deliveryTypes, _pack$deliveryTypes2;
          var pickup = pack == null ? void 0 : (_pack$deliveryTypes = pack.deliveryTypes) == null ? void 0 : _pack$deliveryTypes.find(function (val) {
            return val.type === 'store_pickup';
          });
          var conventional = (_pack$deliveryTypes2 = pack.deliveryTypes) == null ? void 0 : _pack$deliveryTypes2.find(function (val) {
            return val.type === 'conventional';
          });
          return {
            days: (conventional == null ? void 0 : conventional.time.toString()) || undefined,
            days_pickup: (pickup == null ? void 0 : pickup.time.toString()) || undefined,
            default_ship: conditionalToString(conventional),
            shipping_value: pack.price,
            store_pickup: conditionalToString(pickup)
          };
        });
      }),
      _product$shipping$map2 = _slicedToArray(_product$shipping$map, 1),
      firstArray = _product$shipping$map2[0];
    return firstArray[0];
  },
  geral: function geral(product) {
    if (Object.keys(product).length > 0) {
      var _product$financialSer, _product$financialSer2, _product$media, _product$media$images;
      var extendedWarranty = (_product$financialSer = product.financialServices) == null ? void 0 : _product$financialSer.find(function (financial) {
        return financial.description === 'Garantia Estendida';
      });
      var robberyAndTheft = (_product$financialSer2 = product.financialServices) == null ? void 0 : _product$financialSer2.find(function (financial) {
        return financial.description === 'Roubo e Furto';
      });
      var media = getMedias(product.media);
      var _ref = product.price || {},
        bestPrice = _ref.bestPrice,
        price = _ref.price;
      var discountPercenter;
      if (bestPrice && price) {
        discountPercenter = 100 * parseFloat(bestPrice) / parseFloat(price);
        discountPercenter = "".concat(Math.abs(discountPercenter - 100).toFixed(2), "%");
      }
      return {
        discountPercenter: discountPercenter,
        images: (_product$media = product.media) == null ? void 0 : (_product$media$images = _product$media.images) == null ? void 0 : _product$media$images.length.toString(),
        media: media,
        theft: robberyAndTheft ? 'verdadeiro' : 'falso',
        warranty: extendedWarranty ? 'verdadeiro' : 'falso'
      };
    }
    return {};
  },
  service: function service(_ref2) {
    var product = _ref2.product,
      services = _ref2.services;
    if (Object.keys(product).length > 0) {
      var _product$media2, _product$media2$image;
      var _ref3 = services || {},
        extendedWarranty = _ref3.extendedWarranty,
        robberyAndTheft = _ref3.robberyAndTheft;
      var media = getMedias(product.media);
      var _ref4 = product.price || {},
        bestPrice = _ref4.bestPrice,
        price = _ref4.price;
      var discountPercenter;
      if (bestPrice && price) {
        discountPercenter = 100 * parseFloat(bestPrice) / parseFloat(price);
        discountPercenter = "".concat(Math.abs(discountPercenter - 100).toFixed(2), "%");
      }
      return {
        discountPercenter: discountPercenter,
        images: (_product$media2 = product.media) == null ? void 0 : (_product$media2$image = _product$media2.images) == null ? void 0 : _product$media2$image.length.toString(),
        media: media,
        theft: slugServicesIds(robberyAndTheft),
        warranty: slugServicesIds(extendedWarranty)
      };
    }
    return {};
  }
};
var productFormatter = function productFormatter(items, option) {
  if (option) {
    var selectedOption = productFormatterOptions[option];
    return selectedOption(items);
  }
  return {};
};
export default productFormatter;
import _extends from "@babel/runtime/helpers/esm/extends";
import buildClickCommonEvent from '../utils/buildClickCommonEvent';
var buildBuyBoxImpression = function buildBuyBoxImpression() {
  return {
    content_id: 'veja-mais-ofertas-para-este-produto',
    content_type: 'produto',
    event: 'select_content'
  };
};
var buildBuyBoxButtonImpression = function buildBuyBoxButtonImpression(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var product = _ref.product,
    structure = _ref.structure;
  return _extends({
    eventAction: 'add-to-cart:click',
    eventCategory: 'produto:geral',
    eventLabel: 'escolha-a-melhor-oferta',
    eventNonInteraction: '0'
  }, buildClickCommonEvent(_extends({
    accountId: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id
  }, product)));
};
export var buyBoxButtonClick = function buyBoxButtonClick(_ref2) {
  var product = _ref2.product;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildBuyBoxButtonImpression(_extends({
    product: product
  }, args));
};
export var buyBoxSeeMoreOffers = function buyBoxSeeMoreOffers() {
  return buildBuyBoxImpression();
};
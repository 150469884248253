export var getNominalDiscountValue = function getNominalDiscountValue(installment, price) {
  if ((installment == null ? void 0 : installment.totalAmount) && (price == null ? void 0 : price.bestPrice)) {
    return parseFloat(((installment == null ? void 0 : installment.totalAmount) - (price == null ? void 0 : price.bestPrice)).toFixed(2));
  }
  if ((price == null ? void 0 : price.bestPrice) && (price == null ? void 0 : price.fullPrice)) {
    return parseFloat(((price == null ? void 0 : price.fullPrice) - (price == null ? void 0 : price.bestPrice)).toFixed(2));
  }
  return null;
};
export var getPercentualDiscountValue = function getPercentualDiscountValue(price) {
  return (price == null ? void 0 : price.discount) || 'null';
};
import _extends from "@babel/runtime/helpers/esm/extends";
export var clickEvent = function clickEvent(_ref) {
  var _ref$ga = _ref.ga,
    ga = _ref$ga === void 0 ? {} : _ref$ga;
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'porteiro',
    eventLabel: 'cta',
    eventNonInteraction: 0
  }, ga);
};
export var gateKeeperButtonClick = function gateKeeperButtonClick(data) {
  return data && clickEvent(data);
};
import _extends from "@babel/runtime/helpers/esm/extends";
var reviewFormSubmit = function reviewFormSubmit(comment, rating, _ref) {
  var _structure$config;
  var structure = _ref.structure;
  var eventFormSubmit = {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'avaliar-produto',
    eventLabel: "finalizar-avaliacao:".concat(rating, ":").concat((comment == null ? void 0 : comment.length) ? 1 : 0),
    eventNonInteraction: '0',
    screenName: 'avaliar-produto'
  };
  if (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.isMiniApp) {
    return _extends({}, eventFormSubmit, {
      mini_app_name: 'avaliacao'
    });
  }
  return eventFormSubmit;
};
export default reviewFormSubmit;
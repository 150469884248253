import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import { mapNestedKey, locationToString, slug as slugify } from '@magalu/mixer-utils';
import buildClickCommonEvent from '../utils/buildClickCommonEvent';
import gaEcommerceProduct from '../utils/gaEcommerceProduct';
import productFormatter from '../utils/productFormatter';
import getTypeButton from '../utils/getTypeButton';
import { CURRENCY, GENERAL_PRODUCT } from './constants';
export var addToCartError = function addToCartError(_ref) {
  var product = _ref.product,
    error = _ref.error;
  return _extends({
    eventAction: 'add-to-cart:error',
    eventCategory: GENERAL_PRODUCT,
    eventLabel: slugify(error).toLowerCase(),
    eventNonInteraction: '1'
  }, buildClickCommonEvent(product));
};
export var buildCheckoutTrace = function buildCheckoutTrace(_ref2) {
  var eventCategory = _ref2.eventCategory,
    typeButton = _ref2.typeButton;
  return "".concat(eventCategory, "->").concat(typeButton);
};
export var buildCommonEvent = function buildCommonEvent() {
  var _structure$cookies, _structure$cookies$ac, _product$seller, _product$shipping, _product$shipping$, _product$shipping$$pa, _product$shipping$$pa2, _product$price;
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref3$product = _ref3.product,
    product = _ref3$product === void 0 ? {} : _ref3$product,
    services = _ref3.services,
    _ref3$location = _ref3.location,
    location = _ref3$location === void 0 ? {} : _ref3$location,
    _ref3$structure = _ref3.structure,
    structure = _ref3$structure === void 0 ? {} : _ref3$structure,
    eventId = _ref3.eventId;
  var formatter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'geral';
  return {
    currency: CURRENCY,
    ecommerce: {
      add: {
        products: [gaEcommerceProduct(_extends({}, product, {
          accountId: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id,
          id: product == null ? void 0 : product.variationId,
          location: locationToString(location),
          overview: productFormatter(services !== undefined ? {
            product: product,
            services: services
          } : product, formatter),
          shippingValues: productFormatter(product, 'frete')
        }))]
      }
    },
    event: 'addToCart',
    eventAction: 'add-to-cart',
    eventId: eventId,
    eventNonInteraction: '0',
    produtoDisponivel: product == null ? void 0 : product.available,
    sellerEntrega: product == null ? void 0 : (_product$seller = product.seller) == null ? void 0 : _product$seller.deliveryId,
    tipoDeCliente: undefined,
    valorFrete: product == null ? void 0 : (_product$shipping = product.shipping) == null ? void 0 : (_product$shipping$ = _product$shipping[0]) == null ? void 0 : (_product$shipping$$pa = _product$shipping$.packages) == null ? void 0 : (_product$shipping$$pa2 = _product$shipping$$pa[0]) == null ? void 0 : _product$shipping$$pa2.price,
    value: parseFloat(product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.fullPrice)
  };
};
export var buildServicesEventLabel = function buildServicesEventLabel(servicesId, services) {
  if (!(servicesId == null ? void 0 : servicesId.length)) return 'servico:nenhum';
  var selectedServices = services.reduce(function (acc, item) {
    var offerSelected = item == null ? void 0 : item.offers.filter(function (offer) {
      return servicesId.includes(offer.id);
    });
    return [].concat(_toConsumableArray(acc), _toConsumableArray(offerSelected));
  }, []);
  return mapNestedKey(Object.values(selectedServices), 'description').map(function (desc) {
    return "servico:".concat(slugify(desc)).toLowerCase();
  }).join('|');
};
export var addProductToCart = function addProductToCart(_ref4) {
  var location = _ref4.location,
    product = _ref4.product,
    typeButton = _ref4.typeButton,
    _ref4$structure = _ref4.structure,
    structure = _ref4$structure === void 0 ? {} : _ref4$structure,
    eventId = _ref4.eventId;
  return _extends({
    eventCategory: GENERAL_PRODUCT,
    eventLabel: 'adicionar-a-sacola',
    inicioCheckout: buildCheckoutTrace({
      eventCategory: GENERAL_PRODUCT,
      typeButton: typeButton
    }),
    screenName: 'produto'
  }, buildCommonEvent({
    eventId: eventId,
    location: location,
    product: product,
    structure: structure
  }));
};
export var addServicesToCart = function addServicesToCart(_ref5) {
  var location = _ref5.location,
    product = _ref5.product,
    services = _ref5.services,
    typeButton = _ref5.typeButton,
    eventId = _ref5.eventId;
  return _extends({
    eventCategory: 'servico',
    eventLabel: buildServicesEventLabel(services, product == null ? void 0 : product.financialServices),
    inicioCheckout: buildCheckoutTrace({
      eventCategory: 'servico',
      typeButton: typeButton
    }),
    screenName: 'servico'
  }, buildCommonEvent({
    eventId: eventId,
    location: location,
    product: product,
    services: services
  }, 'service'));
};
export var addToCartSuccess = function addToCartSuccess(_ref6) {
  var location = _ref6.location,
    product = _ref6.product,
    services = _ref6.services,
    defaultTypeButton = _ref6.typeButton;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var typeButton = getTypeButton(defaultTypeButton);
  if (services !== undefined) return addServicesToCart(_extends({
    location: location,
    product: product,
    services: services,
    typeButton: typeButton
  }, args));
  return addProductToCart(_extends({
    location: location,
    product: product,
    typeButton: typeButton
  }, args));
};
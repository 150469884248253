import _extends from "@babel/runtime/helpers/esm/extends";
import { whereAmI, useFirebasePush } from '@magalu/mixer-utils';
var dataLayerPush = function dataLayerPush(listener) {
  return function () {
    var _structure$config;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var _ref = args[args.length - 1] || {},
      config = _ref.config,
      structure = _ref.structure;
    var isDevelopment = (config == null ? void 0 : config.environment) === 'development';
    if (!listener || whereAmI.onServer || !window.dataLayer || !document) return;
    var data = listener.apply(void 0, args);
    if (!data) {
      if (isDevelopment) {
        console.warn('[dataLayer.push]: ocorreu uma tentativa de envio de evento sem dados', args);
      }
      return;
    }
    var object = _extends({}, data, {
      hitTimestamp: new Date().getTime()
    });
    if (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.isMiniApp) {
      useFirebasePush(object);
    } else {
      window.dataLayer.push(object);
    }

    if (isDevelopment) {
      var listnerResult = listener.apply(void 0, args);
      if (listnerResult) {
        var event = listnerResult.event,
          eventCategory = listnerResult.eventCategory,
          pageType = listnerResult.pageType,
          contentType = listnerResult.content_type;
        var title = "%c[ga log] ".concat(eventCategory || pageType || contentType, " ").concat(event, "\n");
        console.groupCollapsed(title, 'color: #03A9F4');
        console.log("\uD83D\uDCE1 [dataLayer.push]", _extends({}, listener.apply(void 0, args)));
        console.groupEnd(title);
      }
    }
  };
};
export default dataLayerPush;
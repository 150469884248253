

export var conversationOnView = function conversationOnView(_ref) {
  var userId = _ref.userId,
    userIsLogged = _ref.userIsLogged,
    userLocation = _ref.userLocation,
    sellerName = _ref.sellerName;
  return {
    cepPreenchido: (userLocation == null ? void 0 : userLocation.zipCode) ? 1 : 0,
    cidadeuf: locationToString(userLocation),
    event: 'virtualPageview',
    idUsuario: userId,
    loginAtivo: userIsLogged ? 'logado' : 'nao-logado',
    mini_app_name: 'fale-lojista',
    pageType: 'chat',
    screenName: 'chat-lojista',
    seller: "seller:".concat(sellerName)
  };
};
export var inboxConversationListOnView = function inboxConversationListOnView(_ref2) {
  var userId = _ref2.userId,
    userIsLogged = _ref2.userIsLogged,
    userLocation = _ref2.userLocation;
  return {
    cepPreenchido: userLocation.zipCode ? 1 : 0,
    cidadeuf: locationToString(userLocation),
    event: 'virtualPageview',
    idUsuario: userId,
    loginAtivo: userIsLogged ? 'logado' : 'nao-logado',
    mini_app_name: 'fale-lojista',
    pageType: 'historico-chat',
    screenName: 'historico-chat'
  };
};
export var inboxConversationOnClick = function inboxConversationOnClick(_ref3) {
  var userId = _ref3.userId,
    sellerId = _ref3.sellerId;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "chat-lojista",
    eventLabel: "chat:".concat(sellerId),
    idUsuario: userId,
    mini_app_name: 'fale-lojista'
  };
};
export var locationToString = function locationToString(location) {
  if (!location || (location == null ? void 0 : location.locationError) || !(location == null ? void 0 : location.zipCodeCity) || !(location == null ? void 0 : location.zipCodeState)) {
    return undefined;
  }
  return "".concat(location.zipCodeCity, ":").concat(location.zipCodeState);
};
import _extends from "@babel/runtime/helpers/esm/extends";
import { locationToString, filterByDelivery, slug as slugify } from '@magalu/mixer-utils';
import productFormatter from '../utils/productFormatter';
import gaEcommerceProduct from '../utils/gaEcommerceProduct';
import channelStoreDimensions from '../utils/channelStoreDimensions';
var parseEcommerce = function parseEcommerce(data) {
  return (data == null ? void 0 : data.pageType) === 'produto' && (data == null ? void 0 : data.products) ? {
    ecommerce: {
      detail: {
        products: data.products.map(function (product) {
          return gaEcommerceProduct(_extends({}, product, {
            accountId: data.accountId,
            location: data.location,
            overview: productFormatter(product, 'geral'),
            query: data.query,
            shippingValues: productFormatter(product, 'frete')
          }));
        })
      }
    }
  } : {};
};
var pageView = function pageView(data) {
  var _data$products, _data$products2, _data$products3, _data$products3$, _data$products3$$ship, _data$products3$$ship2, _data$products3$$ship3, _data$products3$$ship4;
  return _extends({
    barraLocalizacao: data.zipCodeBar ? 'verdadeiro' : 'falso',
    cepPreenchido: (data == null ? void 0 : data.zipCode) ? 'verdadeiro' : 'falso',
    cidadeuf: (data == null ? void 0 : data.location) || undefined,
    clientId: data == null ? void 0 : data.clientId,
    device: data.device,
    event: 'virtualPageview',
    existeDelivery: (data == null ? void 0 : data.valuesFilterDelivery) || undefined,
    idParceiro: (data == null ? void 0 : data.partnerId) || undefined,
    idUsuario: (data == null ? void 0 : data.accountId) || undefined,
    impressaoElemento: 1,
    loginAtivo: (data == null ? void 0 : data.accountId) ? 'logado' : 'deslogado',
    pageCategory: (data == null ? void 0 : data.categoryId) || 'nao-se-aplica',
    pageSubcategory: (data == null ? void 0 : data.subCategoryId) || 'nao-se-aplica',
    pageType: data.pageType,
    siteName: data == null ? void 0 : data.site,
    usuarioClube: undefined
  }, data.pageType === 'busca' && {
    termoBuscado: (data == null ? void 0 : data.term) || undefined
  }, (data == null ? void 0 : data.products) && {
    listaVazia: (data == null ? void 0 : (_data$products = data.products) == null ? void 0 : _data$products.length) > 0 ? 'falso' : 'verdadeiro'
  }, {
    valorFrete: data.pageType === 'produto' && (data == null ? void 0 : (_data$products2 = data.products) == null ? void 0 : _data$products2.length) > 0 ? data == null ? void 0 : (_data$products3 = data.products) == null ? void 0 : (_data$products3$ = _data$products3[0]) == null ? void 0 : (_data$products3$$ship = _data$products3$.shipping) == null ? void 0 : (_data$products3$$ship2 = _data$products3$$ship[0]) == null ? void 0 : (_data$products3$$ship3 = _data$products3$$ship2.packages) == null ? void 0 : (_data$products3$$ship4 = _data$products3$$ship3[0]) == null ? void 0 : _data$products3$$ship4.price : undefined
  }, data.storeDimensions, parseEcommerce(data));
};
var willPageViewTrigger = function willPageViewTrigger(siteName, pageType) {
  var excludedSites = ['blog'];
  var excludedPages = ['listagem-de-reviews', 'avaliar-produto', 'service-new'];
  return !(excludedSites.includes(siteName) || excludedPages.includes(pageType));
};
var buildPageView = function buildPageView(listener) {
  return function () {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      structure = _ref.structure,
      data = _ref.data;
    var _ref2 = structure || {},
      _ref2$name = _ref2.name,
      pageType = _ref2$name === void 0 ? 'busca' : _ref2$name,
      _ref2$device = _ref2.device,
      device = _ref2$device === void 0 ? 'mobile' : _ref2$device,
      components = _ref2.components,
      siteName = _ref2.site,
      query = _ref2.query;
    var _ref3 = (structure == null ? void 0 : structure.cookies) || {},
      location = _ref3.location,
      clientId = _ref3.clientId,
      accountData = _ref3.accountData,
      partnerId = _ref3.partnerId;
    var _ref4 = (structure == null ? void 0 : structure.route) || {},
      term = _ref4.term,
      slug = _ref4.slug,
      categoryId = _ref4.categoryId,
      subCategoryId = _ref4.subCategoryId;
    if (willPageViewTrigger(siteName, pageType)) {
      var _data$search, _data$search2, _data$search2$filters, _filterBySlug$values, _filterBySlug$values$;
      var products = pageType === 'produto' && data.product ? [data.product] : data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.products;
      var filterBySlug = data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : (_data$search2$filters = _data$search2.filters) == null ? void 0 : _data$search2$filters.find(filterByDelivery);
      var valuesFilterDelivery = filterBySlug == null ? void 0 : (_filterBySlug$values = filterBySlug.values) == null ? void 0 : (_filterBySlug$values$ = _filterBySlug$values.map(function (item) {
        return slugify(item.label);
      })) == null ? void 0 : _filterBySlug$values$.join('|');
      var storeDimensions = channelStoreDimensions(data == null ? void 0 : data.channel);
      return listener({
        accountId: accountData == null ? void 0 : accountData.id,
        accountName: accountData == null ? void 0 : accountData.name,
        categoryId: categoryId,
        clientId: clientId,
        device: "site-".concat(device),
        location: locationToString(location),
        pageType: pageType,
        partnerId: partnerId,
        products: products,
        query: query,
        slug: slug,
        storeDimensions: storeDimensions,
        subCategoryId: subCategoryId,
        term: term,
        valuesFilterDelivery: valuesFilterDelivery,
        zipCode: location == null ? void 0 : location.zipCode,
        zipCodeBar: components == null ? void 0 : components.find(function (_ref5) {
          var name = _ref5.name;
          return name === 'ZipCodeBar';
        })
      });
    }
    return null;
  };
};
export default buildPageView(pageView);
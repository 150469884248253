import _extends from "@babel/runtime/helpers/esm/extends";
var reviewFormSubmitModal = function reviewFormSubmitModal(_ref) {
  var _structure$config;
  var structure = _ref.structure;
  var eventFormSubmitModal = {
    content_id: 'voltar-ao-inicio',
    content_type: 'avaliacao-concluida',
    event: 'view_content'
  };
  if (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.isMiniApp) {
    return _extends({}, eventFormSubmitModal, {
      mini_app_name: 'avaliacao'
    });
  }
  return eventFormSubmitModal;
};
export default reviewFormSubmitModal;
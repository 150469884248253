import _extends from "@babel/runtime/helpers/esm/extends";
var buildAttributeImpression = function buildAttributeImpression() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    type = _ref.type,
    value = _ref.value;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:variacao',
    eventLabel: "".concat(type, ":").concat(value),
    eventNonInteraction: '0'
  };
};
var buildPaymentMethodImpression = function buildPaymentMethodImpression() {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:forma-de-pagamento',
    eventNonInteraction: '0'
  };
};
var Enum = {
  luiza_ouro: 'cartao-luiza',
  visa: 'cartao-de-credito'
};
export var buildActualPaymentClick = function buildActualPaymentClick(_ref2) {
  var _ref2$paymentId = _ref2.paymentId,
    paymentId = _ref2$paymentId === void 0 ? 'visa' : _ref2$paymentId;
  return _extends({}, buildPaymentMethodImpression(), {
    eventLabel: "visualizar:".concat(Enum[paymentId] || paymentId)
  });
};
export var buildPaymentMethodChange = function buildPaymentMethodChange(_ref3) {
  var paymentId = _ref3.paymentId;
  return _extends({}, buildPaymentMethodImpression(), {
    eventLabel: "selecionar:".concat(Enum[paymentId] || paymentId)
  });
};
export var productAttributeClick = function productAttributeClick(data) {
  return buildAttributeImpression(data);
};
export var productPaymentMethodChange = function productPaymentMethodChange(data) {
  return buildPaymentMethodChange(data);
};
export var productPaymentMethodClick = function productPaymentMethodClick(data) {
  return buildActualPaymentClick(data);
};
import _extends from "@babel/runtime/helpers/esm/extends";
import buildData from '../utils/buildClickCommonEvent';
import getTypeButton from '../utils/getTypeButton';
var buildEventLabels = function buildEventLabels(type) {
  return type === 'typeFloat' ? {
    eventCategory: 'produto:flutuante',
    eventLabel: getTypeButton('oneTouch')
  } : {
    eventCategory: 'produto:geral',
    eventLabel: getTypeButton(type)
  };
};
var buildBuyButtonClick = function buildBuyButtonClick(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var _ref$structure = _ref.structure,
    structure = _ref$structure === void 0 ? {} : _ref$structure,
    product = _ref.product,
    typeButton = _ref.typeButton;
  return _extends({
    event: 'clickEvent'
  }, buildEventLabels(typeButton), {
    eventAction: 'add-to-cart:click',
    eventNonInteraction: '0'
  }, buildData(_extends({}, product, {
    accountId: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id
  })));
};
export var buyButtonClick = function buyButtonClick(_ref2) {
  var product = _ref2.product,
    typeButton = _ref2.typeButton;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildBuyButtonClick(_extends({}, args, {
    product: product,
    typeButton: typeButton
  }));
};
import { isInternationalPrice, normalize } from '@magalu/mixer-utils';
var hasKey = function hasKey() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var key = arguments.length > 1 ? arguments[1] : undefined;
  return Object.prototype.hasOwnProperty.call(obj, key);
};
var getOfferTag = function getOfferTag(product) {
  var _product$offerTags, _product$offerTags$;
  var noTag = 'sem-tag';
  var hasOfferTags = hasKey(product, 'offerTags');
  return hasOfferTags ? "".concat(((_product$offerTags = product.offerTags) == null ? void 0 : (_product$offerTags$ = _product$offerTags[0]) == null ? void 0 : _product$offerTags$.replace(/_/g, '-')) || noTag) : null;
};
var getInternationalTag = function getInternationalTag(product) {
  return isInternationalPrice(product) ? 'compra-internacional' : null;
};
var getBadgeSeal = function getBadgeSeal(product) {
  var _product$badges, _product$badges$;
  var noSeal = 'sem-selo';
  var hasBadges = hasKey(product, 'badges');
  return hasBadges ? "".concat(normalize((_product$badges = product.badges) == null ? void 0 : (_product$badges$ = _product$badges[0]) == null ? void 0 : _product$badges$.text) || noSeal) : null;
};
export var formattedTagSeal = function formattedTagSeal(product) {
  var offerTag = getOfferTag(product);
  var internationalTag = getInternationalTag(product);
  var badgeSeal = getBadgeSeal(product);
  var tag = [offerTag, internationalTag].filter(Boolean).join('|') || null;
  return {
    seal: badgeSeal,
    tag: tag
  };
};
import { magaluCompanies, slug } from '@magalu/mixer-utils';
import { wishlistItem } from './wishlistItem';
import { getNominalDiscountValue, getPercentualDiscountValue } from './discountCalc';
import { buildDimension8 } from './buildDimensions';
import { formattedTagSeal } from './formattedTagSelo';
var buildDimension7 = function buildDimension7(priceData) {
  var price = (priceData == null ? void 0 : priceData.price) || null;
  var bestPrice = (priceData == null ? void 0 : priceData.bestPrice) || null;
  var fullPrice = (priceData == null ? void 0 : priceData.fullPrice) || null;
  return "preco-de:".concat(price !== null ? price : 'null', "|pix:").concat(bestPrice !== null ? bestPrice : 'null', "|mpay:null|boleto:").concat(fullPrice !== null ? fullPrice : 'null');
};
var buildDimension23 = function buildDimension23(seller, togglePdpSellerScore) {
  var _seller$details, _seller$details$score;
  return seller.category === '1p' || !!magaluCompanies[seller.id] ? '0' : "".concat(Number(!!togglePdpSellerScore) || '1', ":").concat(((_seller$details = seller.details) == null ? void 0 : (_seller$details$score = _seller$details.score) == null ? void 0 : _seller$details$score.toString()) || null);
};
var buildDimension41 = function buildDimension41(offerTags) {
  return "etiqueta:".concat((offerTags == null ? void 0 : offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0');
};
var buildDimension112 = function buildDimension112(shipping) {
  if (!shipping) return null;
  var standarDelivery = 'null';
  var standardDeliveryTime = 'null';
  var storePickup = 'null';
  shipping.forEach(function (item) {
    if (item.type === 'conventional') {
      var _item$shippingTime, _item$cost;
      standarDelivery = item == null ? void 0 : (_item$shippingTime = item.shippingTime) == null ? void 0 : _item$shippingTime.description;
      standardDeliveryTime = item == null ? void 0 : (_item$cost = item.cost) == null ? void 0 : _item$cost.customer;
    }
    if (item.type === 'store_pickup') {
      var _item$shippingTime2;
      storePickup = item == null ? void 0 : (_item$shippingTime2 = item.shippingTime) == null ? void 0 : _item$shippingTime2.description;
    }
  });
  var deliveryString = "entrega-padrao:".concat(slug(standarDelivery) || null, ":").concat(standardDeliveryTime || 0, "|retira-loja:").concat(slug(storePickup) || null, ":null");
  return deliveryString.slice(0, 99) || null;
};
export var viewItemDimensions = function viewItemDimensions(data) {
  var _data$product, _data$product$seller, _data$product2, _data$product3, _data$product4, _data$structure, _data$product5, _data$product5$rating, _data$product5$rating2, _data$product6, _data$product6$rating, _data$product6$rating2, _data$product7, _data$product8, _data$product8$seller, _data$product9, _data$product9$seller, _data$product10, _data$product11, _data$product12, _data$deliveries, _data$deliveries$, _data$product13, _data$product14, _data$product14$media, _data$product14$media2;
  var _formattedTagSeal = formattedTagSeal(data == null ? void 0 : data.product),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    dimension5: (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$seller = _data$product.seller) == null ? void 0 : _data$product$seller.category) || null,
    dimension7: buildDimension7(data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.price),
    dimension8: buildDimension8(data),
    dimension11: "".concat((data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : _data$product3.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
    dimension23: buildDimension23(data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : _data$product4.seller, data == null ? void 0 : (_data$structure = data.structure) == null ? void 0 : _data$structure.togglePdpSellerScore),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : (_data$product5$rating = _data$product5.rating) == null ? void 0 : (_data$product5$rating2 = _data$product5$rating.count) == null ? void 0 : _data$product5$rating2.toString(), "|av:").concat(data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : (_data$product6$rating = _data$product6.rating) == null ? void 0 : (_data$product6$rating2 = _data$product6$rating.score) == null ? void 0 : _data$product6$rating2.toString()),
    dimension41: buildDimension41(data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : _data$product7.offerTags),
    dimension61: (data == null ? void 0 : (_data$product8 = data.product) == null ? void 0 : (_data$product8$seller = _data$product8.seller) == null ? void 0 : _data$product8$seller.id) || null,
    dimension62: (data == null ? void 0 : (_data$product9 = data.product) == null ? void 0 : (_data$product9$seller = _data$product9.seller) == null ? void 0 : _data$product9$seller.deliveryId) || null,
    dimension77: "nominal:".concat(getNominalDiscountValue(data == null ? void 0 : (_data$product10 = data.product) == null ? void 0 : _data$product10.installment, data == null ? void 0 : (_data$product11 = data.product) == null ? void 0 : _data$product11.price), "|percentual:").concat(getPercentualDiscountValue(data == null ? void 0 : (_data$product12 = data.product) == null ? void 0 : _data$product12.price)),
    dimension86: seal,
    dimension87: tag,
    dimension112: buildDimension112(data == null ? void 0 : (_data$deliveries = data.deliveries) == null ? void 0 : (_data$deliveries$ = _data$deliveries[0]) == null ? void 0 : _data$deliveries$.modalities),
    dimension115: wishlistItem(data == null ? void 0 : (_data$product13 = data.product) == null ? void 0 : _data$product13.variationId),
    dimension120: (data == null ? void 0 : (_data$product14 = data.product) == null ? void 0 : (_data$product14$media = _data$product14.media) == null ? void 0 : (_data$product14$media2 = _data$product14$media.images) == null ? void 0 : _data$product14$media2.length) || null
  };
};
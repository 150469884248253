export var itemVariantParser = function itemVariantParser(attributes) {
  if (!attributes || !Array.isArray(attributes)) {
    return '';
  }
  var values = attributes.map(function (item) {
    var tipo = item.type || '';
    var valor = item.value || '';
    return [tipo, valor].filter(Boolean).join(':');
  }).filter(Boolean).join('|');
  return values.replace(/[_\s]/g, '-').replace(/,(?:-\s*)/g, ',').toLowerCase();
};
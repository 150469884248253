import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { ACCOUNT, DOMAIN, PAGES } from './utils/constants';
var getView = function getView(_ref) {
  var _data$product, _data$search, _data$search$products;
  var data = _ref.data,
    structure = _ref.structure;
  switch (structure == null ? void 0 : structure.id) {
    case 'home':
      return {
        event: 'viewHome'
      };
    case 'product':
      return {
        event: 'viewItem',
        item: data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id
      };
    default:
      return {
        event: 'viewList',
        item: data == null ? void 0 : (_data$search = data.search) == null ? void 0 : (_data$search$products = _data$search.products) == null ? void 0 : _data$search$products.reduce(function (acc, item) {
          return [].concat(_toConsumableArray(acc), [item.id]);
        }, [])
      };
  }
};
var pageView = function pageView() {
  var _window, _window2, _window2$criteo_q;
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    data = _ref2.data,
    structure = _ref2.structure;
  if (PAGES.includes(structure == null ? void 0 : structure.id) && ((_window = window) == null ? void 0 : _window.criteo_q) && ((_window2 = window) == null ? void 0 : (_window2$criteo_q = _window2.criteo_q) == null ? void 0 : _window2$criteo_q.push)) {
    var _structure$cookies, _structure$cookies$ac;
    window.criteo_q.push({
      account: ACCOUNT,
      event: 'setAccount'
    }, {
      event: 'setSiteType',
      type: DOMAIN
    }, {
      email: (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.email) || '',
      event: 'setEmail'
    }, getView({
      data: data,
      structure: structure
    }));
  }
};
export default pageView;
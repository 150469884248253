import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { setCookie, cookiesConstants } from '@magalu/mixer-utils';
var ML2_SID = cookiesConstants.ML2_SID,
  GA_ADD_TO_CART = cookiesConstants.GA_ADD_TO_CART;
var encoder = function encoder(string) {
  return string;
};
var addToCartSuccessSetCookies = function addToCartSuccessSetCookies(_ref) {
  var _structure$config, _structure$config2;
  var product = _ref.product,
    cartId = _ref.cartId,
    _ref$encode = _ref.encode,
    encode = _ref$encode === void 0 ? encoder : _ref$encode;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref2.structure;
  setCookie({
    domain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain,
    encode: encode,
    name: GA_ADD_TO_CART,
    value: [_defineProperty({}, product == null ? void 0 : product.variationId, product == null ? void 0 : product.id)]
  });
  setCookie({
    domain: structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.cookieDomain,
    name: ML2_SID,
    value: cartId
  });
};
export { addToCartSuccessSetCookies, encoder };
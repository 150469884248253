export var CURRENCY = 'BRL';
export var DELIVERY = {
  delivery_24h: 'entrega-em-1-dia-util',
  delivery_plus_1: 'entrega-em-2-dias-uteis',
  magalu_indica: 'magalu-indica',
  'magalu-entregas': 'entregue-por-magalu',
  'magalu-partner': 'perto-de-voce',
  promocao_sazonal_1: 'ofertas-de-natal',
  promocao_sazonal_2: 'cupons',
  promocao_sazonal_3: 'super-ofertas',
  promocao_sazonal_4: 'ofertas-fantasticas',
  store_pickup_available: 'retira-na-loja'
};
export var GENERAL_PRODUCT = 'produto:geral';
import { magaluCompanies, slug } from '@magalu/mixer-utils';
import { wishlistItem } from './wishlistItem';
import { getNominalDiscountValue, getPercentualDiscountValue } from './discountCalc';
import { buildDimension8, buildDimension9 } from './buildDimensions';
import { formattedTagSeal } from './formattedTagSelo';
var buildDimension7 = function buildDimension7(priceData) {
  var price = (priceData == null ? void 0 : priceData.price) || null;
  var bestPrice = (priceData == null ? void 0 : priceData.bestPrice) || null;
  var fullPrice = (priceData == null ? void 0 : priceData.fullPrice) || null;
  return "preco-de:".concat(price !== null ? price : 'null', "|pix:").concat(bestPrice !== null ? bestPrice : 'null', "|mpay:null|boleto:").concat(fullPrice !== null ? fullPrice : 'null');
};
var buildDimension23 = function buildDimension23(seller, togglePdpSellerScore) {
  var _seller$details, _seller$details$score;
  return seller.category === '1p' || !!magaluCompanies[seller.id] ? '0' : "".concat(Number(!!togglePdpSellerScore) || '1', ":").concat(((_seller$details = seller.details) == null ? void 0 : (_seller$details$score = _seller$details.score) == null ? void 0 : _seller$details$score.toString()) || null);
};
var buildDimension41 = function buildDimension41(offerTags) {
  return "etiqueta:".concat((offerTags == null ? void 0 : offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0');
};
var buildDimension112 = function buildDimension112(shipping) {
  if (!shipping) return null;
  var standarDelivery = 'null';
  var standardDeliveryTime = 'null';
  var storePickup = 'null';
  shipping.forEach(function (item) {
    if (item.type === 'conventional') {
      standarDelivery = item.description;
      standardDeliveryTime = item.price;
    }
    if (item.type === 'store_pickup') {
      storePickup = item.description;
    }
  });
  return "entrega-padrao:".concat(slug(standarDelivery), ":").concat(slug(standardDeliveryTime), "|retira-loja:").concat(slug(storePickup), ":null");
};
export var addToCartDimensions = function addToCartDimensions(data) {
  var _data$product, _data$product$seller, _data$product2, _data$product3, _data$product4, _data$product5, _data$product6, _data$product6$rating, _data$product6$rating2, _data$product7, _data$product7$rating, _data$product7$rating2, _data$product8, _data$product9, _data$product9$seller, _data$product10, _data$product10$selle, _data$product11, _data$product12, _data$product13, _buildDimension, _data$product14, _data$product14$shipp, _data$product14$shipp2, _data$product14$shipp3, _data$product14$shipp4, _data$product15, _data$product16, _data$product16$media, _data$product16$media2;
  var _formattedTagSeal = formattedTagSeal(data == null ? void 0 : data.product),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    dimension5: (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$seller = _data$product.seller) == null ? void 0 : _data$product$seller.category) || null,
    dimension7: buildDimension7(data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.price),
    dimension8: buildDimension8(data),
    dimension9: buildDimension9(data.product),
    dimension11: "".concat((data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : _data$product3.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
    dimension23: buildDimension23(data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : _data$product4.seller, data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : _data$product5.togglePdpSellerScore),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : (_data$product6$rating = _data$product6.rating) == null ? void 0 : (_data$product6$rating2 = _data$product6$rating.count) == null ? void 0 : _data$product6$rating2.toString(), "|av:").concat(data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : (_data$product7$rating = _data$product7.rating) == null ? void 0 : (_data$product7$rating2 = _data$product7$rating.score) == null ? void 0 : _data$product7$rating2.toString()),
    dimension41: buildDimension41(data == null ? void 0 : (_data$product8 = data.product) == null ? void 0 : _data$product8.offerTags),
    dimension61: (data == null ? void 0 : (_data$product9 = data.product) == null ? void 0 : (_data$product9$seller = _data$product9.seller) == null ? void 0 : _data$product9$seller.id) || null,
    dimension62: (data == null ? void 0 : (_data$product10 = data.product) == null ? void 0 : (_data$product10$selle = _data$product10.seller) == null ? void 0 : _data$product10$selle.deliveryId) || null,
    dimension77: "nominal:".concat((data == null ? void 0 : data.pageName) === 'buybox' ? null : getNominalDiscountValue(data == null ? void 0 : (_data$product11 = data.product) == null ? void 0 : _data$product11.installment, data == null ? void 0 : (_data$product12 = data.product) == null ? void 0 : _data$product12.price), "|percentual:").concat(getPercentualDiscountValue(data == null ? void 0 : (_data$product13 = data.product) == null ? void 0 : _data$product13.price)),
    dimension86: seal,
    dimension87: tag,
    dimension112: (_buildDimension = buildDimension112(data == null ? void 0 : (_data$product14 = data.product) == null ? void 0 : (_data$product14$shipp = _data$product14.shipping) == null ? void 0 : (_data$product14$shipp2 = _data$product14$shipp[0]) == null ? void 0 : (_data$product14$shipp3 = _data$product14$shipp2.packages) == null ? void 0 : (_data$product14$shipp4 = _data$product14$shipp3[0]) == null ? void 0 : _data$product14$shipp4.deliveryTypes)) == null ? void 0 : _buildDimension.slice(0, 99),
    dimension115: wishlistItem(data == null ? void 0 : (_data$product15 = data.product) == null ? void 0 : _data$product15.variationId),
    dimension120: (data == null ? void 0 : (_data$product16 = data.product) == null ? void 0 : (_data$product16$media = _data$product16.media) == null ? void 0 : (_data$product16$media2 = _data$product16$media.images) == null ? void 0 : _data$product16$media2.length) || null
  };
};
var buildBenefitsLinkClick = function buildBenefitsLinkClick(_ref) {
  var eventLabel = _ref.eventLabel;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:beneficios',
    eventLabel: eventLabel,
    eventNonInteraction: 0
  };
};
var buldBenefitsImpression = function buldBenefitsImpression(_ref2) {
  var labels = _ref2.labels,
    offerTags = _ref2.offerTags;
  var label;
  if (offerTags == null ? void 0 : offerTags.includes('magalu_indica')) {
    label = labels.join(':');
  } else {
    label = labels.slice(1).join(':');
  }
  return {
    event: 'clickEvent',
    eventAction: 'impression',
    eventCategory: 'produto:beneficios',
    eventLabel: label,
    eventNonInteraction: 0
  };
};
export var productBenefitsLinkClick = function productBenefitsLinkClick() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    eventLabel = _ref3.eventLabel;
  return eventLabel && buildBenefitsLinkClick({
    eventLabel: eventLabel
  });
};
export var productBenefitsView = function productBenefitsView(_ref4) {
  var labels = _ref4.labels,
    offerTags = _ref4.offerTags;
  return labels && buldBenefitsImpression({
    labels: labels,
    offerTags: offerTags
  });
};
import _extends from "@babel/runtime/helpers/esm/extends";
import { slug } from '@magalu/mixer-utils';
var eventCategory = 'produto:frete';
var buildShippingCalculateItemId = function buildShippingCalculateItemId(data) {
  var _deliveries$, _deliveries$2, _structure$route;
  var deliveries = data.deliveries,
    structure = data.structure;
  var isShippingAvailable = (deliveries == null ? void 0 : (_deliveries$ = deliveries[0]) == null ? void 0 : _deliveries$.status.code) || 'unavailable';
  var deliveryModalities = Array.from(new Set(deliveries == null ? void 0 : (_deliveries$2 = deliveries[0]) == null ? void 0 : _deliveries$2.modalities.map(function (_ref) {
    var type = _ref.type;
    return type;
  }))).join(':');
  return "".concat(structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.productId, ":").concat(isShippingAvailable).concat(deliveryModalities.length ? ":".concat(deliveryModalities) : '');
};
var buildShippingClick = function buildShippingClick(data) {
  var _data$deliveries, _data$deliveries$;
  return {
    action: 'click',
    cep: data.zipcode,
    content_type: eventCategory,
    deliveriesId: (_data$deliveries = data.deliveries) == null ? void 0 : (_data$deliveries$ = _data$deliveries[0]) == null ? void 0 : _data$deliveries$.id,
    event: 'shipping',
    item_id: buildShippingCalculateItemId(data)
  };
};
var buildShippingError = function buildShippingError(data) {
  var _data$structure, _data$structure$route;
  return {
    event: 'clickEvent',
    eventAction: 'erro',
    eventCategory: eventCategory,
    eventLabel: "consulta-prazo:".concat((_data$structure = data.structure) == null ? void 0 : (_data$structure$route = _data$structure.route) == null ? void 0 : _data$structure$route.productId, ":erro:").concat(slug(data.error)),
    eventNonInteraction: '1'
  };
};
var buildShippingImpression = function buildShippingImpression(data) {
  return _extends({}, buildShippingClick(data), {
    action: 'impression'
  });
};
export var shippingClick = function shippingClick(data, args) {
  return buildShippingClick(_extends({}, data, args));
};
export var shippingError = function shippingError(data, args) {
  return buildShippingError(_extends({}, data, args));
};
export var shippingImpression = function shippingImpression(data, args) {
  return buildShippingImpression(_extends({}, data, args));
};
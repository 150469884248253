import _extends from "@babel/runtime/helpers/esm/extends";
var eventClickAttach = {
  content_type: 'avaliar-produto',
  event: 'select_content'
};
var buildAttachEvent = function buildAttachEvent(contentId, structure) {
  var _structure$config;
  var event = _extends({
    content_id: contentId
  }, eventClickAttach);
  if (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.isMiniApp) {
    return _extends({}, event, {
      mini_app_name: 'avaliacoes'
    });
  }
  return event;
};
export var clickAttachImageEvent = function clickAttachImageEvent(_ref) {
  var structure = _ref.structure;
  return buildAttachEvent('enviar-foto', structure);
};
export var clickAttachVideoEvent = function clickAttachVideoEvent(_ref2) {
  var structure = _ref2.structure;
  return buildAttachEvent('enviar-video', structure);
};
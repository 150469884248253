import { setCookie, cookiesConstants } from '@magalu/mixer-utils';
var ACCEPT_POLICY = cookiesConstants.ACCEPT_POLICY;
var addToAcceptPolityCookies = function addToAcceptPolityCookies() {
  var _structure$config;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref.structure;
  setCookie({
    domain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain,
    name: ACCEPT_POLICY,
    value: 'true'
  });
};
export { addToAcceptPolityCookies };
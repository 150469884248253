import { setCookie, cookiesConstants } from '@magalu/mixer-utils';
var REJECT_ZIPCODE_POPUP = cookiesConstants.REJECT_ZIPCODE_POPUP;
var addRejectZipcodePopup = function addRejectZipcodePopup() {
  var _config$zipcode, _config$zipcode$popup, _structure$config;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref.structure,
    config = _ref.config;
  var expires = new Date(Date.now() + (config == null ? void 0 : (_config$zipcode = config.zipcode) == null ? void 0 : (_config$zipcode$popup = _config$zipcode.popup) == null ? void 0 : _config$zipcode$popup.rejectPopupExpiresInterval));
  setCookie({
    domain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain,
    expires: expires,
    name: REJECT_ZIPCODE_POPUP,
    value: 'true'
  });
};
export { addRejectZipcodePopup };
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["accountId", "id", "variationId", "title"];
import { slug } from '@magalu/mixer-utils';
import { CURRENCY } from '../events/constants';
import categoryToString from './categoryToString';
import gaBoolean from './gaBoolean';
var gaEcommerceProduct = function gaEcommerceProduct() {
  var _data$brand, _data$category, _data$subcategory, _data$installment, _data$seller, _data$seller2, _data$attributes, _data$attributes2, _data$attributes2$, _data$shippingValues, _data$shippingValues2, _data$shippingValues3, _data$shippingValues4, _data$shippingValues5, _data$offerTags, _data$overview, _data$overview2, _data$financialServic, _data$financialServic2, _data$financialServic3, _data$seller3, _data$overview3, _data$overview4, _data$rating, _data$rating$score, _data$price, _data$price2, _data$overview5, _data$installment2, _data$installment2$qu, _data$rating2, _data$rating2$count, _data$badges, _data$badges$, _data$geoLocTags, _data$query, _data$price3, _data$price4;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var accountId = _ref.accountId,
    id = _ref.id,
    variationId = _ref.variationId,
    name = _ref.title,
    data = _objectWithoutProperties(_ref, _excluded);
  return {
    brand: (data == null ? void 0 : (_data$brand = data.brand) == null ? void 0 : _data$brand.label) || undefined,
    category: categoryToString(data == null ? void 0 : (_data$category = data.category) == null ? void 0 : _data$category.id, data == null ? void 0 : (_data$subcategory = data.subcategory) == null ? void 0 : _data$subcategory.id),
    currency: CURRENCY,
    dimension12: gaBoolean(data == null ? void 0 : data.available),
    dimension13: accountId ? gaBoolean(data == null ? void 0 : data.isOnWishlist) : undefined,
    dimension14: 'falso',
    dimension15: data == null ? void 0 : (_data$installment = data.installment) == null ? void 0 : _data$installment.totalAmount,
    dimension16: data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category,
    dimension17: data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id,
    dimension18: (data == null ? void 0 : (_data$attributes = data.attributes) == null ? void 0 : _data$attributes.length) ? data == null ? void 0 : (_data$attributes2 = data.attributes) == null ? void 0 : (_data$attributes2$ = _data$attributes2[0]) == null ? void 0 : _data$attributes2$.value : undefined,
    dimension19: data == null ? void 0 : data.term,
    dimension20: data == null ? void 0 : (_data$shippingValues = data.shippingValues) == null ? void 0 : _data$shippingValues.store_pickup,
    dimension21: data == null ? void 0 : (_data$shippingValues2 = data.shippingValues) == null ? void 0 : _data$shippingValues2.default_ship,
    dimension22: data == null ? void 0 : (_data$shippingValues3 = data.shippingValues) == null ? void 0 : _data$shippingValues3.shipping_value,
    dimension23: data == null ? void 0 : (_data$shippingValues4 = data.shippingValues) == null ? void 0 : _data$shippingValues4.days,
    dimension24: data == null ? void 0 : (_data$shippingValues5 = data.shippingValues) == null ? void 0 : _data$shippingValues5.days_pickup,
    dimension41: "etiqueta:".concat((data == null ? void 0 : (_data$offerTags = data.offerTags) == null ? void 0 : _data$offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0'),
    dimension44: data == null ? void 0 : (_data$overview = data.overview) == null ? void 0 : _data$overview.warranty,
    dimension45: data == null ? void 0 : (_data$overview2 = data.overview) == null ? void 0 : _data$overview2.theft,
    dimension46: (data == null ? void 0 : (_data$financialServic = data.financialServices) == null ? void 0 : (_data$financialServic2 = _data$financialServic.filter(function (item) {
      return item.description === 'Troca Certa';
    })[0]) == null ? void 0 : (_data$financialServic3 = _data$financialServic2.offers) == null ? void 0 : _data$financialServic3.reduce(function (ac, item) {
      return ac + (ac === '' ? item.treeId : " | ".concat(item.treeId));
    }, '')) || 'none',
    dimension57: gaBoolean(data == null ? void 0 : data.isBuyBox),
    dimension58: undefined,
    dimension62: data == null ? void 0 : (_data$seller3 = data.seller) == null ? void 0 : _data$seller3.deliveryId,
    dimension66: data == null ? void 0 : (_data$overview3 = data.overview) == null ? void 0 : _data$overview3.images,
    dimension67: data == null ? void 0 : (_data$overview4 = data.overview) == null ? void 0 : _data$overview4.media,
    dimension68: data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : (_data$rating$score = _data$rating.score) == null ? void 0 : _data$rating$score.toString(),
    dimension70: data == null ? void 0 : (_data$price = data.price) == null ? void 0 : _data$price.price,
    dimension71: undefined,
    dimension72: data.location,
    dimension76: data == null ? void 0 : (_data$price2 = data.price) == null ? void 0 : _data$price2.bestPrice,
    dimension77: data == null ? void 0 : (_data$overview5 = data.overview) == null ? void 0 : _data$overview5.discountPercenter,
    dimension78: data == null ? void 0 : (_data$installment2 = data.installment) == null ? void 0 : (_data$installment2$qu = _data$installment2.quantity) == null ? void 0 : _data$installment2$qu.toString(),
    dimension79: data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : (_data$rating2$count = _data$rating2.count) == null ? void 0 : _data$rating2$count.toString(),
    dimension85: undefined,
    dimension86: "selo:".concat((data == null ? void 0 : (_data$badges = data.badges) == null ? void 0 : (_data$badges$ = _data$badges[0]) == null ? void 0 : _data$badges$.text) || 'sem-selo', "|tags:").concat(slug(data == null ? void 0 : (_data$geoLocTags = data.geoLocTags) == null ? void 0 : _data$geoLocTags.title) || 'sem-tag'),
    dimension87: undefined,
    dimension103: (data == null ? void 0 : (_data$query = data.query) == null ? void 0 : _data$query.chatbot_lu) ? 'chatbot-lu' : undefined,
    id: id,
    name: name,
    price: Math.round((data == null ? void 0 : (_data$price3 = data.price) == null ? void 0 : _data$price3.bestPrice) * 1e2) / 1e2 || Math.round((data == null ? void 0 : (_data$price4 = data.price) == null ? void 0 : _data$price4.price) * 1e2) / 1e2 || undefined,
    quantity: 1,
    variant: variationId
  };
};
export default gaEcommerceProduct;
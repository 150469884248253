import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["eventAction", "eventLabel", "channelDimensions"];
import { locationToString, normalize } from '@magalu/mixer-utils';
import channelStoreDimensions from '../utils/channelStoreDimensions';
var clickEvent = function clickEvent(data, _ref) {
  var _args$data;
  var eventAction = _ref.eventAction,
    eventLabel = _ref.eventLabel,
    channelDimensions = _ref.channelDimensions,
    args = _objectWithoutProperties(_ref, _excluded);
  return _extends({
    cidadeuf: (data == null ? void 0 : data.location) || undefined,
    contextoDaBusca: (data == null ? void 0 : data.origin) || undefined,
    event: 'clickEvent',
    eventAction: eventAction,
    eventCategory: 'busca:autocomplete',
    eventLabel: eventLabel || (data == null ? void 0 : data.position) || undefined,
    eventNonInteraction: '0',
    termoBuscado: (data == null ? void 0 : data.searchValue) || undefined,
    termoDigitado: (data == null ? void 0 : data.term) || undefined
  }, (args == null ? void 0 : (_args$data = args.data) == null ? void 0 : _args$data.channel) && _extends({}, channelStoreDimensions(args.data.channel)));
};
export var buildSearch = function buildSearch(listener) {
  return function () {
    var _args$structure, _args$structure$cooki, _args$structure2, _args$structure2$rout;
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      origin = _ref2.origin,
      _ref2$term = _ref2.term,
      term = _ref2$term === void 0 ? '' : _ref2$term,
      position = _ref2.position;
    var args = arguments.length > 1 ? arguments[1] : undefined;
    return listener({
      location: locationToString(args == null ? void 0 : (_args$structure = args.structure) == null ? void 0 : (_args$structure$cooki = _args$structure.cookies) == null ? void 0 : _args$structure$cooki.location),
      origin: origin,
      position: position,
      searchValue: normalize(term),
      term: normalize(args == null ? void 0 : (_args$structure2 = args.structure) == null ? void 0 : (_args$structure2$rout = _args$structure2.route) == null ? void 0 : _args$structure2$rout.term)
    }, args);
  };
};
var onClear = function onClear(data, args) {
  return clickEvent(data, _extends({
    eventAction: 'click',
    eventLabel: 'limpar'
  }, args));
};
export var searchClear = buildSearch(onClear);
var onClose = function onClose(data, args) {
  return clickEvent(data, _extends({
    eventAction: 'click',
    eventLabel: 'fechar'
  }, args));
};
export var searchClose = buildSearch(onClose);
var onSearch = function onSearch(data, args) {
  return clickEvent(data, _extends({
    eventAction: 'pesquisar',
    eventLabel: 'termo'
  }, args));
};
export var searchSubmit = buildSearch(onSearch);
var onClick = function onClick(data, args) {
  return clickEvent(data, _extends({
    eventAction: 'click'
  }, args));
};
export var suggestionClick = buildSearch(onClick);
var onClickTop = function onClickTop(data, args) {
  return clickEvent(data, _extends({
    eventAction: 'click',
    eventLabel: data.searchValue ? "top:".concat(data == null ? void 0 : data.searchValue) : undefined
  }, args));
};
export var topTermClick = buildSearch(onClickTop);
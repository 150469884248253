import _extends from "@babel/runtime/helpers/esm/extends";
import { viewItemDimensions } from '../utils/viewItemDimensions';
import { slug } from '@magalu/mixer-utils';
import { itemVariantParser } from '../utils/itemVariantParser';
import { parseUrl } from '../utils/parseUrl';
import { formattedTagSeal } from '../utils/formattedTagSelo';
import { CURRENCY } from './constants';
var parseItemCategory = function parseItemCategory(category, subcategory) {
  var categoryId = slug((category == null ? void 0 : category.id) || 'null');
  var subcategoryId = slug((subcategory == null ? void 0 : subcategory.id) || 'null');
  return "".concat(categoryId, "/").concat(subcategoryId);
};
var parseEcommerce = function parseEcommerce(data) {
  var _data$product, _data$product$brand, _data$product2, _data$product3, _data$product4, _data$product4$rating, _data$product4$rating2, _data$product5, _data$product5$rating, _data$product5$rating2, _data$product6, _data$product$seller$, _data$product7, _data$product7$seller, _data$product$seller$2, _data$product8, _data$product8$seller, _data$product9, _slug, _slug$replace, _slug$replace$toLower, _data$product10, _data$product11, _data$product12, _data$product12$insta, _data$product13, _data$product13$price, _data$product14, _data$product14$price;
  var _formattedTagSeal = formattedTagSeal(data == null ? void 0 : data.product),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    items: [_extends({
      item_brand: slug((data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$brand = _data$product.brand) == null ? void 0 : _data$product$brand.label) || 'null'),
      item_category: parseItemCategory(data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.category, data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : _data$product3.subcategory),
      item_category3: "qtd-av:".concat((data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : (_data$product4$rating = _data$product4.rating) == null ? void 0 : (_data$product4$rating2 = _data$product4$rating.count) == null ? void 0 : _data$product4$rating2.toString()) || null, "|av:").concat((data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : (_data$product5$rating = _data$product5.rating) == null ? void 0 : (_data$product5$rating2 = _data$product5$rating.score) == null ? void 0 : _data$product5$rating2.toString()) || null, "|").concat((data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : _data$product6.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
      item_category4: "ven-por:".concat((_data$product$seller$ = data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : (_data$product7$seller = _data$product7.seller) == null ? void 0 : _data$product7$seller.id) !== null && _data$product$seller$ !== void 0 ? _data$product$seller$ : null, "|ent-por:").concat((_data$product$seller$2 = data == null ? void 0 : (_data$product8 = data.product) == null ? void 0 : (_data$product8$seller = _data$product8.seller) == null ? void 0 : _data$product8$seller.deliveryId) !== null && _data$product$seller$2 !== void 0 ? _data$product$seller$2 : null),
      item_category5: "selo:".concat(seal, "|tag:").concat(tag),
      item_id: (data == null ? void 0 : (_data$product9 = data.product) == null ? void 0 : _data$product9.variationId) || null,
      item_name: (_slug = slug((data == null ? void 0 : (_data$product10 = data.product) == null ? void 0 : _data$product10.title) || 'null')) == null ? void 0 : (_slug$replace = _slug.replace(/[_\s]/g, '-')) == null ? void 0 : (_slug$replace$toLower = _slug$replace.toLowerCase()) == null ? void 0 : _slug$replace$toLower.slice(0, 99),
      item_variant: itemVariantParser(data == null ? void 0 : (_data$product11 = data.product) == null ? void 0 : _data$product11.attributes) || null,
      price: parseFloat(data == null ? void 0 : (_data$product12 = data.product) == null ? void 0 : (_data$product12$insta = _data$product12.installment) == null ? void 0 : _data$product12$insta.totalAmount) || parseFloat(data == null ? void 0 : (_data$product13 = data.product) == null ? void 0 : (_data$product13$price = _data$product13.price) == null ? void 0 : _data$product13$price.fullPrice) || parseFloat(data == null ? void 0 : (_data$product14 = data.product) == null ? void 0 : (_data$product14$price = _data$product14.price) == null ? void 0 : _data$product14$price.bestPrice) || null
    }, viewItemDimensions(data))]
  };
};
var viewItem = function viewItem(newData) {
  var _newData$product, _newData$product2, _newData$product3, _data$structure, _data$structure$cooki, _data$structure$cooki2, _data$product15, _data$product15$insta, _data$product16, _data$product16$price, _data$product17, _data$product17$price;
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    eventId = _ref.eventId;
  var data = _extends({}, newData, {
    product: _extends({}, newData.product, {
      category: _extends({}, newData.product.category, {
        id: newData.product.category.id || parseUrl(newData == null ? void 0 : (_newData$product = newData.product) == null ? void 0 : _newData$product.url, 1)
      }),
      subcategory: _extends({}, newData.product.subcategory, {
        id: newData.product.subcategory.id || parseUrl(newData == null ? void 0 : (_newData$product2 = newData.product) == null ? void 0 : _newData$product2.url, 2)
      }),
      variationId: newData.product.variationId || parseUrl(newData == null ? void 0 : (_newData$product3 = newData.product) == null ? void 0 : _newData$product3.url, 0)
    })
  });
  return _extends({
    currency: CURRENCY,
    event: 'view_item',
    eventId: eventId,
    idUsuario: (data == null ? void 0 : (_data$structure = data.structure) == null ? void 0 : (_data$structure$cooki = _data$structure.cookies) == null ? void 0 : (_data$structure$cooki2 = _data$structure$cooki.accountData) == null ? void 0 : _data$structure$cooki2.customer_id) || null,
    mundos: 'magalu',
    screenName: 'produto',
    value: parseFloat(data == null ? void 0 : (_data$product15 = data.product) == null ? void 0 : (_data$product15$insta = _data$product15.installment) == null ? void 0 : _data$product15$insta.totalAmount) || parseFloat(data == null ? void 0 : (_data$product16 = data.product) == null ? void 0 : (_data$product16$price = _data$product16.price) == null ? void 0 : _data$product16$price.fullPrice) || parseFloat(data == null ? void 0 : (_data$product17 = data.product) == null ? void 0 : (_data$product17$price = _data$product17.price) == null ? void 0 : _data$product17$price.bestPrice) || null
  }, parseEcommerce(data));
};
export default viewItem;
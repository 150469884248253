import { slug } from '@magalu/mixer-utils';
import { ACCOUNT, EVENT_CLIENT, EVENT_PRODUCT, DOMAIN } from './utils/constants';
var pageView = function pageView() {
  var _window, _window2, _window2$Btg;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref.structure,
    data = _ref.data;
  if (((_window = window) == null ? void 0 : _window.Btg360) && ((_window2 = window) == null ? void 0 : (_window2$Btg = _window2.Btg360) == null ? void 0 : _window2$Btg.add)) {
    var _structure$cookies, _structure$cookies$ac;
    if (structure.id === 'product') {
      var _data$product, _data$product$brand, _data$product2, _data$product2$subcat, _data$product3, _data$product3$catego, _data$product4, _data$product5, _data$product6, _data$product6$price, _data$product7, _data$product7$price;
      window.Btg360.add({
        account: ACCOUNT,
        domain: DOMAIN,
        event: EVENT_PRODUCT,
        items: [{
          brand: data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$brand = _data$product.brand) == null ? void 0 : _data$product$brand.label,
          category: slug((data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$subcat = _data$product2.subcategory) == null ? void 0 : _data$product2$subcat.id) || ''),
          department: slug((data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$catego = _data$product3.category) == null ? void 0 : _data$product3$catego.id) || ''),
          id: data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : _data$product4.id,
          name: data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : _data$product5.title,
          price: (data == null ? void 0 : (_data$product6 = data.product) == null ? void 0 : (_data$product6$price = _data$product6.price) == null ? void 0 : _data$product6$price.bestPrice) || (data == null ? void 0 : (_data$product7 = data.product) == null ? void 0 : (_data$product7$price = _data$product7.price) == null ? void 0 : _data$product7$price.price),
          subcategory: ''
        }]
      });
    }
    window.Btg360.add({
      account: ACCOUNT,
      domain: DOMAIN,
      event: EVENT_CLIENT,
      items: [{
        email: (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.email) || ''
      }]
    });
  }
};
export default pageView;
var blogPageView = function blogPageView(_ref) {
  var structure = _ref.structure;
  return {
    clientId: structure == null ? void 0 : structure.cookies.clientId,
    device: structure == null ? void 0 : structure.device,
    event: 'virtualPageview',
    idParceiro: structure == null ? void 0 : structure.cookies.partnerId,
    idUsuario: structure == null ? void 0 : structure.cookies.customerId,
    pageType: "blog-".concat(structure == null ? void 0 : structure.id),
    screenName: "blog ".concat(structure == null ? void 0 : structure.name)
  };
};
export var buildBlogPageView = function buildBlogPageView(_ref2) {
  var structure = _ref2.structure;
  var siteName = structure.site;
  if (siteName === 'blog' || siteName === 'blog-mobile') {
    return blogPageView({
      structure: structure
    });
  }
  return null;
};
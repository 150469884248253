var buildEvent = function buildEvent(_ref) {
  var label = _ref.label,
    variationId = _ref.variationId;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:etiqueta',
    eventLabel: "".concat(label, ":").concat(variationId),
    eventNonInteraction: 0
  };
};
export var productLabelClick = function productLabelClick() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    label = _ref2.label,
    variationId = _ref2.variationId;
  return label && variationId && buildEvent({
    label: label,
    variationId: variationId
  });
};
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["structure"];
import channelStoreDimensions from '../utils/channelStoreDimensions';
var clickEvent = function clickEvent(_ref2, _ref) {
  var _args$data;
  var eventLabel = _ref2.eventLabel;
  var structure = _ref.structure,
    args = _objectWithoutProperties(_ref, _excluded);
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat(structure == null ? void 0 : structure.name, ":paginacao"),
    eventLabel: eventLabel,
    eventNonInteraction: '0'
  }, (args == null ? void 0 : (_args$data = args.data) == null ? void 0 : _args$data.channel) && _extends({}, channelStoreDimensions(args.data.channel)));
};
export var paginateClick = function paginateClick() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return clickEvent(data, args);
};
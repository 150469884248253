var category = function category() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$products = _ref.products,
    products = _ref$products === void 0 ? [] : _ref$products,
    userId = _ref.customer_id,
    offset = _ref.offset,
    attributionToken = _ref.attributionToken,
    pageName = _ref.pageName,
    categoryInfo = _ref.category,
    abTesting = _ref.abTesting;
  if (pageName === 'categoria' && abTesting) {
    var _categoryInfo$;
    var categoryLabel = categoryInfo == null ? void 0 : (_categoryInfo$ = categoryInfo[0]) == null ? void 0 : _categoryInfo$.id;
    var pageCategories = [categoryLabel];
    var productDetails = products.map(function (product) {
      return {
        product: {
          id: product.variationId
        }
      };
    });
    return {
      cloud_retail: {
        attributionToken: attributionToken,
        eventType: 'search',
        offset: offset,
        pageCategories: pageCategories,
        productDetails: productDetails,
        userInfo: {
          userId: userId
        }
      },
      event: 'cloud_retail'
    };
  }
  return null;
};
export default category;
import _extends from "@babel/runtime/helpers/esm/extends";
import { locationToString, normalize } from '@magalu/mixer-utils';
import channelStoreDimensions from '../utils/channelStoreDimensions';
var sort = function sort() {
  var _data$sort, _args$structure, _args$structure$cooki, _args$structure2, _data$sort2, _args$structure3, _args$structure3$rout, _args$structure4, _args$structure4$rout, _args$structure5, _args$structure5$rout, _args$structure6, _args$structure6$rout, _args$data;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (data == null ? void 0 : (_data$sort = data.sort) == null ? void 0 : _data$sort.label) && _extends({
    cidadeuf: locationToString(args == null ? void 0 : (_args$structure = args.structure) == null ? void 0 : (_args$structure$cooki = _args$structure.cookies) == null ? void 0 : _args$structure$cooki.location),
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat((args == null ? void 0 : (_args$structure2 = args.structure) == null ? void 0 : _args$structure2.name) || '', ":ordenacao"),
    eventLabel: data == null ? void 0 : (_data$sort2 = data.sort) == null ? void 0 : _data$sort2.label,
    eventNonInteraction: '0',
    pageCategory: (args == null ? void 0 : (_args$structure3 = args.structure) == null ? void 0 : (_args$structure3$rout = _args$structure3.route) == null ? void 0 : _args$structure3$rout.categoryId) || 'nao-se-aplica',
    pageSubcategory: (args == null ? void 0 : (_args$structure4 = args.structure) == null ? void 0 : (_args$structure4$rout = _args$structure4.route) == null ? void 0 : _args$structure4$rout.subCategoryId) || 'nao-se-aplica',
    termoBuscado: normalize(args == null ? void 0 : (_args$structure5 = args.structure) == null ? void 0 : (_args$structure5$rout = _args$structure5.route) == null ? void 0 : _args$structure5$rout.term),
    termoDigitado: normalize(args == null ? void 0 : (_args$structure6 = args.structure) == null ? void 0 : (_args$structure6$rout = _args$structure6.route) == null ? void 0 : _args$structure6$rout.term)
  }, (args == null ? void 0 : (_args$data = args.data) == null ? void 0 : _args$data.channel) && _extends({}, channelStoreDimensions(args.data.channel)));
};
export default sort;
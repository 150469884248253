import _extends from "@babel/runtime/helpers/esm/extends";
var eventClickMedia = {
  content_type: 'produto:avaliacao',
  event: 'select_content'
};
export var clickCondensedMediaImage = function clickCondensedMediaImage() {
  return _extends({}, eventClickMedia, {
    content_id: 'imagem-avaliacoes'
  });
};
export var clickCondensedMediaVideo = function clickCondensedMediaVideo() {
  return _extends({}, eventClickMedia, {
    content_id: 'video-avaliacoes'
  });
};
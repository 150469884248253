import { slug as slugify } from '@magalu/mixer-utils';
export var postClick = function postClick(_ref, _ref2) {
  var title = _ref.title,
    categoryId = _ref.categoryId,
    recomendationLayout = _ref.recomendationLayout;
  var structure = _ref2.structure;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "blog:".concat(structure == null ? void 0 : structure.id, ":").concat(recomendationLayout),
    eventLabel: "post:".concat(categoryId, ":").concat(slugify(title))
  };
};
import _extends from "@babel/runtime/helpers/esm/extends";
export var clickEvent = function clickEvent(_ref, config) {
  var _config$structure;
  var text = _ref.text,
    _ref$ga = _ref.ga,
    ga = _ref$ga === void 0 ? {} : _ref$ga;
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat(config == null ? void 0 : (_config$structure = config.structure) == null ? void 0 : _config$structure.name, ":hotlinks"),
    eventLabel: text,
    eventNonInteraction: 0
  }, ga);
};
export var hotlinkClick = function hotlinkClick(data, config) {
  return data && clickEvent(data, config);
};
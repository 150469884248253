import _extends from "@babel/runtime/helpers/esm/extends";
var eventCategory = 'servicos';
var screenName = 'servicos';
export var addService = function addService(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "incluir-protecao:".concat(args == null ? void 0 : args.offers),
    eventNonInteraction: 0,
    screenName: screenName
  };
};
export var clickService = function clickService(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: args == null ? void 0 : args.eventLabel,
    eventNonInteraction: 0,
    screenName: screenName
  };
};
export var refuseService = function refuseService() {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: 'agora-nao',
    eventNonInteraction: 0,
    screenName: screenName
  };
};
export var servicesKnowMore = function servicesKnowMore(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "saiba-mais-servico:".concat(args == null ? void 0 : args.service),
    eventNonInteraction: 0,
    screenName: screenName
  };
};
export var servicesLegalInfo = function servicesLegalInfo(args) {
  return {
    content_type: eventCategory,
    event: 'select_content',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "saiba-mais-informacoes-garantia:".concat(args == null ? void 0 : args.service),
    eventNonInteraction: 0,
    item_id: "saiba-mais-informacoes-garantia:".concat(args == null ? void 0 : args.service),
    screenName: screenName
  };
};
export var servicesMoreDetails = function servicesMoreDetails(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "mais-detalhes-do-seguro:".concat(args == null ? void 0 : args.service),
    eventNonInteraction: 0,
    screenName: screenName
  };
};
export var servicesOfferChange = function servicesOfferChange(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "".concat((args == null ? void 0 : args.higher) ? 'aumentar-protecao' : 'diminuir-protecao', ":").concat(args == null ? void 0 : args.service),
    eventNonInteraction: 0,
    screenName: screenName
  };
};
var viewItem = function viewItem(data) {
  var _data$product, _data$product$seller, _data$product2, _data$product2$seller;
  return _extends({
    barraLocalizacao: (data == null ? void 0 : data.zipCodeBar) ? 'verdadeiro' : 'falso',
    cepPreenchido: (data == null ? void 0 : data.zipCode) ? 1 : 0,
    cidadeuf: (data == null ? void 0 : data.location) ? "cep:".concat(data == null ? void 0 : data.zipCode, ":").concat(data == null ? void 0 : data.location) : undefined,
    clientId: data == null ? void 0 : data.clientId,
    device: data == null ? void 0 : data.device,
    event: 'virtualPageview',
    existeDelivery: (data == null ? void 0 : data.valuesFilterDelivery) || undefined,
    experimentId: undefined,
    idDaloja: data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$seller = _data$product.seller) == null ? void 0 : _data$product$seller.deliveryId,
    idParceiro: (data == null ? void 0 : data.partnerId) || undefined,
    idUsuario: (data == null ? void 0 : data.accountId) || undefined,
    impressaoElemento: 1,
    loginAtivo: (data == null ? void 0 : data.accountId) ? 'logado' : 'nao-logado',
    mundos: 'magalu',
    nomeDaLoja: data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$seller = _data$product2.seller) == null ? void 0 : _data$product2$seller.deliveryDescription,
    pageCategory: (data == null ? void 0 : data.categoryId) || 'nao-se-aplica',
    pageSubcategory: (data == null ? void 0 : data.subCategoryId) || 'nao-se-aplica',
    pageType: 'servicos',
    screenName: screenName,
    siteName: data == null ? void 0 : data.site,
    usuarioClube: undefined
  }, data == null ? void 0 : data.storeDimensions);
};
var buildServicesPageView = function buildServicesPageView(eventListener) {
  return function (props) {
    var data = props.data,
      structure = props.structure;
    var pageId = structure.id;
    if (pageId === 'service-new') {
      return eventListener(data, structure);
    }
    return null;
  };
};
export var servicesPageView = buildServicesPageView(viewItem);
export var servicesVideo = function servicesVideo(args) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: "video-lu-explica:".concat(args == null ? void 0 : args.service),
    eventNonInteraction: 0,
    screenName: screenName
  };
};
import _extends from "@babel/runtime/helpers/esm/extends";
import { getNominalDiscountValue } from '../utils/discountCalc';
import { formattedTagSeal } from '../utils/formattedTagSelo';
import { itemVariantParser } from '../utils/itemVariantParser';
import { parseUrl } from '../utils/parseUrl';
import { selectItemDimensions } from '../utils/selectItemDimensions';
import { slug } from '@magalu/mixer-utils';
var parseItemCategory = function parseItemCategory(category, subcategory) {
  var categoryId = slug((category == null ? void 0 : category.id) || 'null');
  var subcategoryId = slug((subcategory == null ? void 0 : subcategory.id) || 'null');
  return "".concat(categoryId, "/").concat(subcategoryId);
};
var priceValue = function priceValue(data) {
  var _data$installment, _data$price, _data$price2;
  return parseFloat(data == null ? void 0 : (_data$installment = data.installment) == null ? void 0 : _data$installment.totalAmount) || parseFloat(data == null ? void 0 : (_data$price = data.price) == null ? void 0 : _data$price.fullPrice) || parseFloat(data == null ? void 0 : (_data$price2 = data.price) == null ? void 0 : _data$price2.bestPrice) || null;
};
var parseEcommerce = function parseEcommerce(data) {
  var _data$brand, _data$rating, _data$rating$count, _data$rating2, _data$rating2$score, _data$seller$id, _data$seller, _data$seller$delivery, _data$seller2;
  var _formattedTagSeal = formattedTagSeal(data),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    items: [_extends({
      discount: getNominalDiscountValue(data == null ? void 0 : data.installment, data == null ? void 0 : data.price),
      index: (data == null ? void 0 : data.position) || null,
      item_brand: slug((data == null ? void 0 : (_data$brand = data.brand) == null ? void 0 : _data$brand.label) || 'null'),
      item_category: parseItemCategory(data == null ? void 0 : data.category, data == null ? void 0 : data.subcategory),
      item_category3: "qtd-av:".concat((data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : (_data$rating$count = _data$rating.count) == null ? void 0 : _data$rating$count.toString()) || null, "|av:").concat((data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : (_data$rating2$score = _data$rating2.score) == null ? void 0 : _data$rating2$score.toString()) || null, "|").concat((data == null ? void 0 : data.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
      item_category4: "ven-por:".concat((_data$seller$id = data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.id) !== null && _data$seller$id !== void 0 ? _data$seller$id : null, "|ent-por:").concat((_data$seller$delivery = data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.deliveryId) !== null && _data$seller$delivery !== void 0 ? _data$seller$delivery : null),
      item_category5: "selo:".concat(seal, "|tag:").concat(tag),
      item_id: (data == null ? void 0 : data.variationId) || null,
      item_list_name: "".concat(slug((data == null ? void 0 : data.pageName) || 'null'), ":").concat((data == null ? void 0 : data.term) || slug((data == null ? void 0 : data.list) || 'null')),
      item_name: slug((data == null ? void 0 : data.title) || 'null').replace(/[_\s]/g, '-').toLowerCase().slice(0, 99),
      item_variant: itemVariantParser(data == null ? void 0 : data.attributes) || null,
      price: priceValue(data)
    }, selectItemDimensions(data))]
  };
};
var viewItemList = function viewItemList(newData) {
  var _data$customer_id;
  var data = _extends({}, newData, {
    category: _extends({}, newData.category, {
      id: newData.category.id || parseUrl(newData == null ? void 0 : newData.url, 1)
    }),
    subcategory: _extends({}, newData.subcategory, {
      id: newData.subcategory.id || parseUrl(newData == null ? void 0 : newData.url, 2)
    }),
    variationId: newData.variationId || parseUrl(newData == null ? void 0 : newData.url, 0)
  });
  return _extends({
    event: 'view_item_list',
    eventAction: 'impression',
    eventCategory: "".concat(slug((data == null ? void 0 : data.pageName) || 'null'), ":magalu:vitrine"),
    eventLabel: "".concat(slug((data == null ? void 0 : data.displayMode) || 'null'), ":").concat(slug((data == null ? void 0 : data.list) || 'null'), ":slot-null"),
    idUsuario: (_data$customer_id = data == null ? void 0 : data.customer_id) !== null && _data$customer_id !== void 0 ? _data$customer_id : null,
    item_list_name: "".concat(slug((data == null ? void 0 : data.pageName) || 'null'), ":").concat((data == null ? void 0 : data.term) || slug((data == null ? void 0 : data.list) || 'null')),
    mundos: 'magalu',
    screenName: (data == null ? void 0 : data.pageName) || null
  }, parseEcommerce(data), (data == null ? void 0 : data.term) ? {
    raw: data.term
  } : {});
};
export default viewItemList;
import { normalize } from '@magalu/mixer-utils';
var stringNormalize = function stringNormalize(label) {
  return normalize(label == null ? void 0 : label.replace == null ? void 0 : label.replace(/\s/g, '-'));
};
var accountLinkClick = function accountLinkClick(event) {
  return {
    content_type: 'seu-espaco',
    event: 'select_content',
    item_id: "".concat(stringNormalize(event == null ? void 0 : event.category), ":").concat(stringNormalize(event == null ? void 0 : event.item)),
    screenName: 'meu-espaco'
  };
};
export default accountLinkClick;
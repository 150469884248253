var buildContinueToCartImpression = function buildContinueToCartImpression(data) {
  var _data$shipping, _data$shipping$, _data$shipping$$packa, _data$shipping2, _data$shipping2$, _data$shipping2$$pack, _data$shipping2$$pack2, _data$shipping2$$pack3, _data$category, _data$seller, _data$seller2, _data$seller3, _data$seller4, _data$subcategory;
  var shippingPackage = (data == null ? void 0 : (_data$shipping = data.shipping) == null ? void 0 : (_data$shipping$ = _data$shipping[0]) == null ? void 0 : (_data$shipping$$packa = _data$shipping$.packages) == null ? void 0 : _data$shipping$$packa[0]) || {};
  var hasPickupStore = !!(data == null ? void 0 : (_data$shipping2 = data.shipping) == null ? void 0 : (_data$shipping2$ = _data$shipping2[0]) == null ? void 0 : (_data$shipping2$$pack = _data$shipping2$.packages) == null ? void 0 : (_data$shipping2$$pack2 = _data$shipping2$$pack[0]) == null ? void 0 : (_data$shipping2$$pack3 = _data$shipping2$$pack2.deliveryTypes) == null ? void 0 : _data$shipping2$$pack3.some(function (el) {
    return el.type === 'store_pickup';
  }));
  return {
    categorias: data == null ? void 0 : (_data$category = data.category) == null ? void 0 : _data$category.id,
    categoriaSeller: data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category,
    event: 'clickEvent',
    eventAction: 'add-to-cart:click',
    eventCategory: 'home:continuar-compra',
    eventLabel: 'continuar-sua-compra',
    eventNonInteraction: '0',
    freteTotal: shippingPackage == null ? void 0 : shippingPackage.price,
    nomeSeller: data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id,
    produtoClube: (data == null ? void 0 : (_data$seller3 = data.seller) == null ? void 0 : _data$seller3.discounts) ? 'verdadeiro' : 'falso',
    produtoDisponivel: (data == null ? void 0 : data.available) ? 'verdadeiro' : 'falso',
    produtoFavoritado: undefined,
    retiraLoja: hasPickupStore ? 'verdadeiro' : 'falso',
    sellerEntrega: data == null ? void 0 : (_data$seller4 = data.seller) == null ? void 0 : _data$seller4.id,
    sku: data == null ? void 0 : data.id,
    subcategorias: data == null ? void 0 : (_data$subcategory = data.subcategory) == null ? void 0 : _data$subcategory.id
  };
};
var continueToCart = function continueToCart(_ref) {
  var product = _ref.product;
  return buildContinueToCartImpression(product);
};
export default continueToCart;
import _extends from "@babel/runtime/helpers/esm/extends";
export var buildLuContent = function buildLuContent(_ref) {
  var id = _ref.id,
    title = _ref.title,
    position = _ref.position;
  return {
    id: id,
    name: title,
    position: position
  };
};
export var buildLuContentClickImpression = function buildLuContentClickImpression() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    pageType = _ref2.pageType,
    id = _ref2.id,
    title = _ref2.title,
    position = _ref2.position,
    _ref2$eventLabel = _ref2.eventLabel,
    eventLabel = _ref2$eventLabel === void 0 ? 'materia' : _ref2$eventLabel;
  return {
    ecommerce: {
      promoClick: {
        promotions: [{
          id: id,
          name: title,
          position: position
        }]
      }
    },
    event: 'promotionClick',
    eventAction: 'click',
    eventCategory: "".concat(pageType, ":lu-explica"),
    eventLabel: eventLabel,
    eventNonInteraction: '0'
  };
};
export var buildLuContentSocialMediaClickImpression = function buildLuContentSocialMediaClickImpression() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    pageType = _ref3.pageType,
    id = _ref3.id;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat(pageType, ":redes-sociais"),
    eventLabel: id,
    eventNonInteraction: '0'
  };
};
export var buildLuContentViewImpression = function buildLuContentViewImpression(_ref4) {
  var _ref4$eventLabel = _ref4.eventLabel,
    eventLabel = _ref4$eventLabel === void 0 ? 'materias' : _ref4$eventLabel,
    pageType = _ref4.pageType,
    _ref4$promotions = _ref4.promotions,
    promotions = _ref4$promotions === void 0 ? [] : _ref4$promotions;
  return {
    ecommerce: {
      promoView: {
        promotions: promotions.map(function (promotion) {
          return buildLuContent(promotion);
        })
      }
    },
    event: 'promotionView',
    eventAction: 'impression',
    eventCategory: "".concat(pageType, ":lu-explica"),
    eventLabel: eventLabel,
    eventNonInteraction: 1
  };
};
export var luContentClick = function luContentClick() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref5.structure;
  return buildLuContentClickImpression(_extends({}, data, {
    pageType: structure == null ? void 0 : structure.name
  }));
};
export var luContentSocialMediaClick = function luContentSocialMediaClick() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref6 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref6.structure;
  return buildLuContentSocialMediaClickImpression(_extends({}, data, {
    pageType: structure == null ? void 0 : structure.name
  }));
};
export var luContentView = function luContentView(data) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref7.structure;
  return buildLuContentViewImpression(_extends({}, data, {
    pageType: structure == null ? void 0 : structure.name
  }));
};
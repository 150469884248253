
import { slug as slugify } from '@magalu/mixer-utils';
export var buildEvent = function buildEvent() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$event = _ref.event,
    event = _ref$event === void 0 ? 'clickEvent' : _ref$event,
    _ref$eventAction = _ref.eventAction,
    eventAction = _ref$eventAction === void 0 ? 'impression' : _ref$eventAction,
    _ref$eventCategory = _ref.eventCategory,
    eventCategory = _ref$eventCategory === void 0 ? 'magalu-has' : _ref$eventCategory,
    eventLabel = _ref.eventLabel;
  return {
    event: event,
    eventAction: eventAction,
    eventCategory: eventCategory,
    eventLabel: eventLabel
  };
};
export var suggestionImpression = function suggestionImpression(data) {
  var label = data.linksSuggestions.links.map(function (item, index) {
    var pipe = item.length === index ? '' : "|";
    return "".concat(slugify(item.value)).concat(pipe);
  });
  var formattedLabel = label.toString().replace(/\,/g, '').replace(/\s/g, '-').replace(/\W$/, '');
  return buildEvent({
    eventLabel: "".concat(formattedLabel.toLowerCase())
  });
};
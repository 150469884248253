import { locationToString } from '@magalu/mixer-utils';
var screenName = 'adicione-protecao';
var sellerName = 'mlseguros';
export var financialServicesSeparateSaleDetailsOnClick = function financialServicesSeparateSaleDetailsOnClick() {
  return {
    content_id: 'detalhes-do-seguro',
    content_type: screenName,
    event: 'select_content'
  };
};
export var financialServicesSeparateSaleOnChange = function financialServicesSeparateSaleOnChange(_ref) {
  var validity = _ref.validity;
  return {
    content_id: "meses-de-garantia-".concat(validity),
    content_type: screenName,
    event: 'select_content'
  };
};
export var financialServicesSeparateSaleOnView = function financialServicesSeparateSaleOnView(_ref2) {
  var device = _ref2.device,
    partnerId = _ref2.partnerId,
    userId = _ref2.userId,
    userIsLogged = _ref2.userIsLogged,
    userLocation = _ref2.userLocation;
  return {
    barraLocalizacao: undefined,
    cepPreenchido: (userLocation == null ? void 0 : userLocation.zipCode) ? 1 : 0,
    cidadeuf: locationToString(userLocation),
    device: "site-".concat(device),
    event: 'virtualPageview',
    existeDelivery: undefined,
    hitTimestamp: new Date().getTime(),
    idDaLoja: sellerName,
    idParceiro: partnerId,
    idUsuario: userId,
    loginAtivo: userIsLogged ? 'logado' : 'nao-logado',
    nomeDaLoja: sellerName,
    pageType: 'servico',
    screenName: screenName,
    usuarioClube: undefined
  };
};
export var financialServicesSeparateSalePaymentOnClick = function financialServicesSeparateSalePaymentOnClick() {
  return {
    content_id: 'ir-para-pagamento',
    content_type: screenName,
    event: 'select_content'
  };
};
export var financialServicesSeparateSaleSeeMoreOnClick = function financialServicesSeparateSaleSeeMoreOnClick() {
  return {
    content_id: 'ver-mais',
    content_type: screenName,
    event: 'select_content'
  };
};
export var financialServicesSeparateSaleTermOnClick = function financialServicesSeparateSaleTermOnClick() {
  return {
    content_id: 'termos-de-autorizacao-cobranca',
    content_type: screenName,
    event: 'select_content'
  };
};
export var financialServicesSeparateSaleVideoOnClick = function financialServicesSeparateSaleVideoOnClick() {
  return {
    content_id: 'ver-video',
    content_type: screenName,
    event: 'select_content'
  };
};
import _extends from "@babel/runtime/helpers/esm/extends";
var buildClick = function buildClick() {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'categoria:subcategoria',
    eventNonInteraction: '0'
  };
};
export var buildSubcategoryFullListClick = function buildSubcategoryFullListClick() {
  return _extends({}, buildClick(), {
    eventLabel: 'veja-a-lista-completa'
  });
};
export var buildSubcategoryListClick = function buildSubcategoryListClick(_ref) {
  var _ref$eventLabel = _ref.eventLabel,
    eventLabel = _ref$eventLabel === void 0 ? '' : _ref$eventLabel;
  return _extends({}, buildClick(), {
    eventLabel: "todas".concat(eventLabel ? ":".concat(eventLabel) : '')
  });
};
export var buildSubcategoryTopClick = function buildSubcategoryTopClick(_ref2) {
  var _ref2$eventLabel = _ref2.eventLabel,
    eventLabel = _ref2$eventLabel === void 0 ? '' : _ref2$eventLabel;
  return _extends({}, buildClick(), {
    eventLabel: "principais".concat(eventLabel ? ":".concat(eventLabel) : '')
  });
};
export var subcategoryFullListClick = function subcategoryFullListClick() {
  return buildSubcategoryFullListClick();
};
export var subcategoryListClick = function subcategoryListClick(data) {
  return buildSubcategoryListClick(data);
};
export var subcategoryTopClick = function subcategoryTopClick(data) {
  return buildSubcategoryTopClick(data);
};
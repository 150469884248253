import { magaluCompanies, slug } from '@magalu/mixer-utils';
import { wishlistItem } from './wishlistItem';
import { getNominalDiscountValue, getPercentualDiscountValue } from './discountCalc';
import { buildDimension8, buildDimension9 } from './buildDimensions';
import { formattedTagSeal } from './formattedTagSelo';
var buildDimension7 = function buildDimension7(priceData) {
  var price = (priceData == null ? void 0 : priceData.price) || null;
  var bestPrice = (priceData == null ? void 0 : priceData.bestPrice) || null;
  var fullPrice = (priceData == null ? void 0 : priceData.fullPrice) || null;
  return "preco-de:".concat(price !== null ? price : 'null', "|pix:").concat(bestPrice !== null ? bestPrice : 'null', "|mpay:null|boleto:").concat(fullPrice !== null ? fullPrice : 'null');
};
var buildDimension23 = function buildDimension23(seller, togglePdpSellerScore) {
  var _seller$details, _seller$details$score;
  return seller.category === '1p' || !!magaluCompanies[seller.id] ? '0' : "".concat(Number(!!togglePdpSellerScore) || '1', ":").concat(((_seller$details = seller.details) == null ? void 0 : (_seller$details$score = _seller$details.score) == null ? void 0 : _seller$details$score.toString()) || null);
};
var buildDimension41 = function buildDimension41(offerTags) {
  return "etiqueta:".concat((offerTags == null ? void 0 : offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0');
};
var buildDimension112 = function buildDimension112(shippingTag) {
  if (!shippingTag) return "entrega-padr\xE3o:null:null|retira-loja:null";
  var cost = (shippingTag == null ? void 0 : shippingTag.cost) === 0 ? 0 : shippingTag.cost || 'null';
  var timeSlug = slug((shippingTag == null ? void 0 : shippingTag.time) || 'null');
  var complementSlug = slug((shippingTag == null ? void 0 : shippingTag.complement) || 'null');
  return "entrega-padr\xE3o:".concat(timeSlug || null, ":").concat(cost || null, "|retira-loja:").concat(complementSlug || null, ":").concat(shippingTag.source || null);
};
export var addToWishlistDimensions = function addToWishlistDimensions(data) {
  var _data$seller, _data$rating, _data$rating$count, _data$rating2, _data$rating2$score, _data$seller2, _data$seller3, _data$origin, _data$ads, _data$ads2, _buildDimension, _data$media, _data$media$images;
  var _formattedTagSeal = formattedTagSeal(data),
    seal = _formattedTagSeal.seal,
    tag = _formattedTagSeal.tag;
  return {
    dimension5: (data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category) || null,
    dimension7: buildDimension7(data == null ? void 0 : data.price),
    dimension8: buildDimension8(data),
    dimension9: buildDimension9(data),
    dimension11: "".concat((data == null ? void 0 : data.available) ? 'verdadeiro' : 'falso:indisponivel-cep'),
    dimension23: buildDimension23(data == null ? void 0 : data.seller, data == null ? void 0 : data.togglePdpSellerScore),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : (_data$rating$count = _data$rating.count) == null ? void 0 : _data$rating$count.toString(), "|av:").concat(data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : (_data$rating2$score = _data$rating2.score) == null ? void 0 : _data$rating2$score.toString()),
    dimension41: buildDimension41(data == null ? void 0 : data.offerTags),
    dimension61: (data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id) || null,
    dimension62: (data == null ? void 0 : (_data$seller3 = data.seller) == null ? void 0 : _data$seller3.deliveryId) || null,
    dimension77: "nominal:".concat(getNominalDiscountValue(data == null ? void 0 : data.installment, data == null ? void 0 : data.price), "|percentual:").concat(getPercentualDiscountValue(data == null ? void 0 : data.price)),
    dimension80: (data == null ? void 0 : data.pageName) === 'busca' ? (data == null ? void 0 : data.term) || null : null,
    dimension86: seal,
    dimension87: tag,
    dimension103: (data == null ? void 0 : (_data$origin = data.origin) == null ? void 0 : _data$origin.includes('ads:')) ? "ads:".concat((data == null ? void 0 : (_data$ads = data.ads) == null ? void 0 : _data$ads.id) || null, "|campaignId:").concat((data == null ? void 0 : (_data$ads2 = data.ads) == null ? void 0 : _data$ads2.campaignId) || null) : "".concat((data == null ? void 0 : data.origin) || null),
    dimension112: (_buildDimension = buildDimension112(data == null ? void 0 : data.shippingTag)) == null ? void 0 : _buildDimension.slice(0, 99),
    dimension115: wishlistItem(data == null ? void 0 : data.variationId),
    dimension120: (data == null ? void 0 : (_data$media = data.media) == null ? void 0 : (_data$media$images = _data$media.images) == null ? void 0 : _data$media$images.length) || null
  };
};
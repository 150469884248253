
import { COMPANY_SUBSCRIBE, EVENT_SUBSCRIBE, ID_SUBSCRIBE, LIST_SUBSCRIBE } from './utils/constants';
var padTo2Digits = function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
};
var formatDate = function formatDate(date) {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
};
var subscribeToNewsletter = function subscribeToNewsletter() {
  var _window, _window$lc;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (window && ((_window = window) == null ? void 0 : (_window$lc = _window.lc) == null ? void 0 : _window$lc.sendData) && window.__blc) {
    window.__blc.id = ID_SUBSCRIBE;
    var name = data.name,
      email = data.email;
    if (name && email) {
      window.lc.sendData({
        evento: EVENT_SUBSCRIBE,
        lista: {
          atualizar: '1',
          dt_cadastro: formatDate(new Date()),
          empresa: COMPANY_SUBSCRIBE,
          nm_lista: LIST_SUBSCRIBE,
          nome: name
        },
        nm_email: email,
        vars: {
          nome: name
        },
        vars_json: {}
      });
    }
  }
};
export default subscribeToNewsletter;
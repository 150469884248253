import { locationToString } from '@magalu/mixer-utils';
var screenName = 'lista-pedidos-elegiveis';
export var itemAdapter = function itemAdapter(item, index) {
  return {
    index: index,
    item_brand: item.productBrand,
    item_id: item.productSku,
    item_name: item.productDescription
  };
};
export var orderEligibleListOnClick = function orderEligibleListOnClick(_ref) {
  var displayMode = _ref.displayMode,
    itemSelected = _ref.itemSelected,
    itemIndex = _ref.itemIndex,
    serviceSlug = _ref.serviceSlug;
  return {
    event: 'select_item',
    eventAction: 'click',
    eventCategory: "".concat(screenName, ":").concat(serviceSlug, ":vitrine"),
    eventLabel: "".concat(displayMode, ":").concat(screenName, ":slot-1:").concat(itemSelected.productSku),
    item: itemAdapter(itemSelected, itemIndex + 1),
    item_list_name: screenName,
    mundos: 'magalu',
    nomeLista: "servico:".concat(screenName),
    pageType: 'servico',
    screenName: screenName
  };
};
export var orderEligibleListOnView = function orderEligibleListOnView(_ref2) {
  var device = _ref2.device,
    partnerId = _ref2.partnerId,
    seller = _ref2.seller,
    userId = _ref2.userId,
    userIsLogged = _ref2.userIsLogged,
    userLocation = _ref2.userLocation;
  return {
    barraLocalizacao: undefined,
    cepPreenchido: (userLocation == null ? void 0 : userLocation.zipCode) ? 1 : 0,
    cidadeuf: locationToString(userLocation),
    device: "site-".concat(device),
    event: 'virtualPageview',
    existeDelivery: undefined,
    hitTimestamp: new Date().getTime(),
    idDaLoja: seller == null ? void 0 : seller.id,
    idParceiro: partnerId,
    idUsuario: userId,
    loginAtivo: userIsLogged ? 'logado' : 'nao-logado',
    nomeDaLoja: seller == null ? void 0 : seller.description,
    pageType: 'servico',
    screenName: screenName,
    usuarioClube: undefined
  };
};
export var orderEligibleListOnViewItemList = function orderEligibleListOnViewItemList(_ref3) {
  var displayMode = _ref3.displayMode,
    products = _ref3.products,
    serviceSlug = _ref3.serviceSlug;
  return {
    event: 'view_item_list',
    eventAction: 'impression',
    eventCategory: "".concat(screenName, ":").concat(serviceSlug, ":vitrine"),
    eventLabel: "".concat(displayMode, ":").concat(screenName, ":slot-1"),
    item_list_name: screenName,
    items: (products || []).map(function (product, index) {
      return itemAdapter(product, index + 1);
    }),
    mundos: 'magalu',
    nomeLista: screenName,
    pageType: 'servico',
    posicaoVitrine: displayMode,
    screenName: screenName
  };
};
export var orderNotEligibleOnView = function orderNotEligibleOnView(_ref4) {
  var serviceSlug = _ref4.serviceSlug;
  return {
    content_id: "".concat(screenName, "-indisponivel"),
    content_type: 'servico',
    event: 'view_content',
    serviceSlug: serviceSlug
  };
};
var buildEvent = function buildEvent(label) {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:beneficios',
    eventLabel: label,
    eventNonInteraction: 0
  };
};
export var productTagsClick = function productTagsClick(label) {
  return label && buildEvent(label);
};
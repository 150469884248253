export var buybuttonServicesOnClick = function buybuttonServicesOnClick(_ref) {
  var _product$price, _product$price2;
  var product = _ref.product;
  return {
    basketId: undefined,
    currency: product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.currency,
    event: 'add_to_cart',
    eventAction: 'add-to-cart',
    eventCategory: 'sacola:produto',
    eventLabel: 'ver-produtos-e-precos',
    item: product,
    screenName: 'carrinho',
    value: Number((product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.fullPrice) || 0)
  };
};
var formatValue = function formatValue(value) {
  return parseFloat(value || 0).toFixed(2);
};
var calculateTaxes = function calculateTaxes(taxes) {
  return {
    event: 'tax',
    imposto: "".concat(formatValue(taxes == null ? void 0 : taxes.importTax), "|icms:").concat(formatValue(taxes == null ? void 0 : taxes.icms)),
    screenName: 'produto'
  };
};
export default calculateTaxes;
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
var _excluded = ["eventLabel", "eventAction"];
import { locationToString, slug } from '@magalu/mixer-utils';
import channelStoreDimensions from '../utils/channelStoreDimensions';
import { DELIVERY } from './constants';
export var buildFilterClick = function buildFilterClick(_ref) {
  var data = _ref.data,
    _ref$eventLabel = _ref.eventLabel,
    eventLabel = _ref$eventLabel === void 0 ? '' : _ref$eventLabel,
    _ref$eventCategory = _ref.eventCategory,
    eventCategory = _ref$eventCategory === void 0 ? 'filtro' : _ref$eventCategory,
    _ref$eventAction = _ref.eventAction,
    eventAction = _ref$eventAction === void 0 ? 'click' : _ref$eventAction,
    structure = _ref.structure;
  return _extends({
    event: 'clickEvent',
    eventAction: eventAction,
    eventCategory: "".concat((structure == null ? void 0 : structure.name) || '', ":").concat(eventCategory),
    eventLabel: eventLabel,
    eventNonInteraction: '0'
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
var filterItemClickLabel = function filterItemClickLabel(custom, selected) {
  if (custom) return 'selecionar';
  return "".concat(selected ? 'remover' : 'adicionar', "-filtro");
};
var filterDeliveryClick = function filterDeliveryClick(_ref2) {
  var _args$structure, _args$structure$cooki;
  var _ref2$eventLabel = _ref2.eventLabel,
    eventLabel = _ref2$eventLabel === void 0 ? '' : _ref2$eventLabel,
    eventAction = _ref2.eventAction,
    args = _objectWithoutProperties(_ref2, _excluded);
  return _extends({
    cidadeuf: locationToString(args == null ? void 0 : (_args$structure = args.structure) == null ? void 0 : (_args$structure$cooki = _args$structure.cookies) == null ? void 0 : _args$structure$cooki.location)
  }, buildFilterClick(_extends({}, args, {
    eventAction: eventAction,
    eventCategory: 'delivery',
    eventLabel: DELIVERY[eventLabel || '']
  })));
};
export var fastFilterClick = function fastFilterClick() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    filterLabel = _ref3.filterLabel,
    filterValue = _ref3.slug;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildFilterClick(_extends({}, args, {
    eventAction: 'click',
    eventCategory: "filtro-rapido",
    eventLabel: "".concat(slug(filterLabel), ":").concat(filterValue)
  }));
};
export var fastFilterView = function fastFilterView() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    eventLabel = _ref4.eventLabel;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildFilterClick(_extends({}, args, {
    eventAction: 'impression',
    eventCategory: "filtro-rapido",
    eventLabel: eventLabel
  }));
};
export var filterClean = function filterClean() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return buildFilterClick(_extends({}, args, {
    eventLabel: 'limpar-tudo'
  }));
};
export var filterClose = function filterClose() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return buildFilterClick(_extends({}, args, {
    eventLabel: 'fechar'
  }));
};
export var filterDeliveryClear = function filterDeliveryClear() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return filterDeliveryClick(_extends({}, args, {
    eventAction: 'limpar'
  }));
};
export var filterDeliverySelect = function filterDeliverySelect() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return filterDeliveryClick(_extends({}, args, {
    eventAction: 'aplicar'
  }));
};
export var filterItemClick = function filterItemClick() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    filterLabel = _ref5.filterLabel,
    _ref5$position = _ref5.position,
    position = _ref5$position === void 0 ? '' : _ref5$position,
    label = _ref5.label,
    _ref5$selected = _ref5.selected,
    selected = _ref5$selected === void 0 ? false : _ref5$selected,
    _ref5$searchTerm = _ref5.searchTerm,
    searchTerm = _ref5$searchTerm === void 0 ? '' : _ref5$searchTerm,
    _ref5$custom = _ref5.custom,
    custom = _ref5$custom === void 0 ? false : _ref5$custom;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildFilterClick(_extends({}, args, {
    eventAction: "".concat(position, ":").concat(slug(filterLabel)),
    eventLabel: "".concat(filterItemClickLabel(custom, selected), ":").concat(position || '', ":").concat(slug(label)).concat(searchTerm && ":".concat(searchTerm))
  }));
};
export var filterLinkListClick = function filterLinkListClick() {
  var _args$structure2;
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    label = _ref6.label;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var pathName = (args == null ? void 0 : (_args$structure2 = args.structure) == null ? void 0 : _args$structure2.name) || '';
  return buildFilterClick(_extends({}, args, {
    eventCategory: 'consultas-relacionadas',
    eventLabel: "".concat(pathName, ":").concat(slug(label))
  }));
};
export var filterOpen = function filterOpen() {
  var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return buildFilterClick(_extends({}, args, {
    eventLabel: 'abrir'
  }));
};
export var filterRemove = function filterRemove() {
  var _args$structure3;
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    position = _ref7.position,
    label = _ref7.label;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _args$structure$route = args == null ? void 0 : (_args$structure3 = args.structure) == null ? void 0 : _args$structure3.route,
    _args$structure$route2 = _args$structure$route.term,
    term = _args$structure$route2 === void 0 ? '' : _args$structure$route2;
  return buildFilterClick(_extends({}, args, {
    eventAction: "".concat(position, ":").concat(slug(label)),
    eventLabel: "remover-filtro-busca:".concat(position, ":").concat(slug(label)).concat(term && ":".concat(term))
  }));
};
export var filterTypeClick = function filterTypeClick() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref8$label = _ref8.label,
    label = _ref8$label === void 0 ? '' : _ref8$label,
    _ref8$position = _ref8.position,
    position = _ref8$position === void 0 ? '' : _ref8$position;
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildFilterClick(_extends({}, args, {
    eventAction: "".concat(position, ":").concat(slug(label)),
    eventLabel: "selecionar-categoria:".concat(slug(label))
  }));
};
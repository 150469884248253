import _extends from "@babel/runtime/helpers/esm/extends";
import { locationToString } from '@magalu/mixer-utils';
import { buildParamsByPath } from './miniapps';
var buildReviewPageView = function buildReviewPageView(_ref) {
  var structure = _ref.structure;
  var _ref2 = structure || {},
    components = _ref2.components,
    cookies = _ref2.cookies,
    id = _ref2.id,
    pageType = _ref2.name,
    route = _ref2.route,
    site = _ref2.site;
  var _ref3 = cookies || {},
    accountData = _ref3.accountData,
    customerId = _ref3.customerId;
  var allowedPageTypes = ['listagem-de-reviews', 'avaliar-produto'];
  var isReview = allowedPageTypes.includes(pageType);
  if (isReview) {
    var _cookies$location;
    var miniAppParams = buildParamsByPath(id, accountData == null ? void 0 : accountData.id, site);
    return _extends({
      barraLocalizacao: Number(!!(components == null ? void 0 : components.some(function (_ref4) {
        var name = _ref4.name;
        return name === 'ZipCodeBar';
      }))),
      cepPreenchido: Number(!!(cookies == null ? void 0 : (_cookies$location = cookies.location) == null ? void 0 : _cookies$location.zipCode)),
      cidadeuf: locationToString(cookies == null ? void 0 : cookies.location),
      device: "site-".concat((structure == null ? void 0 : structure.device) || 'mobile'),
      event: 'virtualPageview',
      existeDelivery: 'falso',
      experimentId: undefined,
      idUsuario: customerId,
      loginAtivo: (accountData == null ? void 0 : accountData.id) ? 'logado' : 'deslogado',
      mundos: null,
      pageType: "".concat(pageType),
      permitirRastreio: undefined,
      screenName: "".concat(pageType),
      sku: route == null ? void 0 : route.productId,
      usuarioClube: null
    }, miniAppParams);
  }
  return null;
};
var buildReviewImpression = function buildReviewImpression() {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'produto:avaliacao',
    eventLabel: 'avaliacoes:avaliar',
    eventNonInteraction: '0'
  };
};
var statusType = {
  ERROR_DUPLICATE_SUBMISSION: 'erro',
  INVALID_PAYLOAD: 'erro',
  OK: 'sucesso'
};
export var productReviewClick = function productReviewClick() {
  return buildReviewImpression();
};
export var productReviewSubmit = function productReviewSubmit(_ref5) {
  var status = _ref5.status,
    errorMessage = _ref5.errorMessage;
  return _extends({}, buildReviewImpression(), {
    eventAction: (statusType == null ? void 0 : statusType[status]) || 'erro',
    eventLabel: errorMessage || 'sucesso'
  });
};
var buildReviewListImpression = function buildReviewListImpression(miniAppName) {
  return _extends({}, buildReviewImpression(), {
    eventCategory: 'avaliacao:geral',
    mini_app_name: miniAppName
  });
};
export var reviewListSort = function reviewListSort() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    miniAppName = _ref6.miniAppName,
    filterLabel = _ref6.filterLabel,
    sku = _ref6.sku;
  return _extends({}, buildReviewListImpression(miniAppName), {
    eventLabel: "".concat(filterLabel, ":").concat(sku)
  });
};
export var reviewPageView = function reviewPageView(args) {
  return buildReviewPageView(args || {});
};
export var reviewPdpClick = function reviewPdpClick() {
  return _extends({}, buildReviewImpression(), {
    eventCategory: 'produto:avaliacao',
    eventLabel: 'ver-todas-as-avaliacoes'
  });
};
export var writeReviewClick = function writeReviewClick() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    miniAppName = _ref7.miniAppName,
    buttonLabel = _ref7.buttonLabel,
    sku = _ref7.sku;
  return _extends({}, buildReviewListImpression(miniAppName), {
    eventLabel: "".concat(buttonLabel, ":").concat(sku)
  });
};
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["accountId", "id", "variationId", "title", "list"],
  _excluded2 = ["eventCategory", "structure"],
  _excluded3 = ["list", "filters", "listPosition", "origin", "display"],
  _excluded4 = ["product", "list", "filters", "listPosition", "origin", "display"];
import { categoryToString, locationToString, gaBoolean, gaSelectedFilters, gaSortFallback, slug } from '@magalu/mixer-utils';
import { channelStoreDimensions, getBestPriceDiscount, getInstallmentQuantity, getDiscount, getSearchTerm, handleOrigin, isSomeParametersEmpty } from '../utils';
var eventLabel = 'product-list';
var displayEnum = {
  gallery: 'galeria',
  list: 'lista',
  miniature: 'miniatura'
};
export var buildProductImpression = function buildProductImpression() {
  var _data$brand, _data$category, _data$subcategory, _data$installment, _data$seller, _data$seller2, _data$attributes, _data$attributes2, _data$attributes2$, _data$offerTags, _data$contextSearch, _data$rating, _data$price, _data$price2, _data$parsedAttribute, _data$parsedAttribute2, _data$parsedAttribute3, _data$rating2, _data$badges, _data$badges$, _data$geoLocTags, _data$shippingTag, _data$shippingTag2, _data$shippingTag3, _data$shippingTag4, _data$shippingTag5, _data$price3, _data$price4;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var accountId = _ref.accountId,
    id = _ref.id,
    variationId = _ref.variationId,
    name = _ref.title,
    list = _ref.list,
    data = _objectWithoutProperties(_ref, _excluded);
  return _extends({
    brand: (data == null ? void 0 : (_data$brand = data.brand) == null ? void 0 : _data$brand.label) || undefined,
    category: categoryToString(data == null ? void 0 : (_data$category = data.category) == null ? void 0 : _data$category.id, data == null ? void 0 : (_data$subcategory = data.subcategory) == null ? void 0 : _data$subcategory.id),
    dimension10: handleOrigin(data),
    dimension11: data == null ? void 0 : data.listPosition,
    dimension12: gaBoolean(data == null ? void 0 : data.available),
    dimension13: accountId ? gaBoolean(data == null ? void 0 : data.isOnWishlist) : undefined,
    dimension14: 'falso',
    dimension15: data == null ? void 0 : (_data$installment = data.installment) == null ? void 0 : _data$installment.totalAmount,
    dimension16: data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category,
    dimension17: data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id,
    dimension18: (data == null ? void 0 : (_data$attributes = data.attributes) == null ? void 0 : _data$attributes.length) ? data == null ? void 0 : (_data$attributes2 = data.attributes) == null ? void 0 : (_data$attributes2$ = _data$attributes2[0]) == null ? void 0 : _data$attributes2$.value : undefined,
    dimension26: data == null ? void 0 : data.term,
    dimension41: "etiqueta:".concat((data == null ? void 0 : (_data$offerTags = data.offerTags) == null ? void 0 : _data$offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0'),
    dimension48: gaSelectedFilters(data == null ? void 0 : data.filters),
    dimension49: data == null ? void 0 : data.orderBy,
    dimension52: (data == null ? void 0 : data.contextSearch) ? data == null ? void 0 : (_data$contextSearch = data.contextSearch) == null ? void 0 : _data$contextSearch.value : 'none',
    dimension60: displayEnum[data == null ? void 0 : data.display],
    dimension62: undefined,
    dimension65: list,
    dimension68: data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : _data$rating.score,
    dimension70: (data == null ? void 0 : (_data$price = data.price) == null ? void 0 : _data$price.bestPrice) ? data == null ? void 0 : (_data$price2 = data.price) == null ? void 0 : _data$price2.price : undefined,
    dimension72: data == null ? void 0 : data.location,
    dimension74: data == null ? void 0 : (_data$parsedAttribute = data.parsedAttributes) == null ? void 0 : (_data$parsedAttribute2 = _data$parsedAttribute[0]) == null ? void 0 : (_data$parsedAttribute3 = _data$parsedAttribute2.values) == null ? void 0 : _data$parsedAttribute3.length,
    dimension76: getBestPriceDiscount(data),
    dimension77: getDiscount(data),
    dimension78: getInstallmentQuantity(data),
    dimension79: data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : _data$rating2.count,
    dimension85: data == null ? void 0 : data.page,
    dimension86: "selo:".concat((data == null ? void 0 : (_data$badges = data.badges) == null ? void 0 : (_data$badges$ = _data$badges[0]) == null ? void 0 : _data$badges$.text) || 'sem-selo', "|tags:").concat(slug(data == null ? void 0 : (_data$geoLocTags = data.geoLocTags) == null ? void 0 : _data$geoLocTags.title) || 'sem-tag')
  }, (data == null ? void 0 : data.showcase) ? {
    dimension90: "".concat(data.showcase.pageId, "-").concat(data.showcase.placeId).concat(data.showcase.id ? "-".concat(data.showcase.id) : ''),
    dimension91: data.showcase.type,
    dimension92: slug(data.showcase.title)
  } : {}, {
    dimension112: !isSomeParametersEmpty(data == null ? void 0 : data.shippingTag) ? "entrega-padr\xE3o:".concat(slug((data == null ? void 0 : (_data$shippingTag = data.shippingTag) == null ? void 0 : _data$shippingTag.time) || 'null'), ":").concat((data == null ? void 0 : (_data$shippingTag2 = data.shippingTag) == null ? void 0 : _data$shippingTag2.cost) === 0 ? 0 : (data == null ? void 0 : (_data$shippingTag3 = data.shippingTag) == null ? void 0 : _data$shippingTag3.cost) || 'null', "|retira-loja:").concat(slug((data == null ? void 0 : (_data$shippingTag4 = data.shippingTag) == null ? void 0 : _data$shippingTag4.complement) || 'null'), ":").concat((data == null ? void 0 : (_data$shippingTag5 = data.shippingTag) == null ? void 0 : _data$shippingTag5.source) || 'null') : undefined,
    id: variationId || id,
    list: list,
    name: name,
    position: data == null ? void 0 : data.position,
    price: (data == null ? void 0 : (_data$price3 = data.price) == null ? void 0 : _data$price3.bestPrice) || (data == null ? void 0 : (_data$price4 = data.price) == null ? void 0 : _data$price4.price) || undefined,
    quantity: '1',
    variant: variationId
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
var buildProductListView = function buildProductListView(_ref3, _ref2) {
  var _ref3$products = _ref3.products,
    products = _ref3$products === void 0 ? [] : _ref3$products,
    _ref3$list = _ref3.list,
    list = _ref3$list === void 0 ? '' : _ref3$list,
    filters = _ref3.filters,
    origin = _ref3.origin,
    display = _ref3.display,
    listPosition = _ref3.listPosition,
    showcase = _ref3.showcase;
  var eventCategory = _ref2.eventCategory,
    structure = _ref2.structure,
    args = _objectWithoutProperties(_ref2, _excluded2);
  return {
    ecommerce: {
      impressions: products.map(function (product) {
        var _structure$cookies, _structure$cookies$ac, _product$price, _structure$cookies2, _gaSortFallback, _structure$route, _structure$route2, _structure$route3, _structure$route4, _structure$route5;
        return buildProductImpression(_extends({}, product, args, {
          accountId: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id,
          discount: Number((product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.discount) || 0) ? "".concat(Number(product.price.discount), "%") : undefined,
          display: slug(display),
          filters: filters,
          list: slug(list),
          listPosition: listPosition,
          location: locationToString(structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.location),
          orderBy: slug(((_gaSortFallback = gaSortFallback({
            orientation: structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.sortOrientation,
            type: structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.sortType
          })) == null ? void 0 : _gaSortFallback.label) || '') || undefined,
          origin: origin,
          page: (structure == null ? void 0 : (_structure$route3 = structure.route) == null ? void 0 : _structure$route3.page) ? "page-".concat(structure == null ? void 0 : (_structure$route4 = structure.route) == null ? void 0 : _structure$route4.page) : 'page-1',
          showcase: showcase,
          term: structure == null ? void 0 : (_structure$route5 = structure.route) == null ? void 0 : _structure$route5.term,
          title: slug(product == null ? void 0 : product.title)
        }));
      })
    },
    event: 'productListView',
    eventAction: 'impression',
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventNonInteraction: '1'
  };
};
var buildProductListClick = function buildProductListClick(_ref4, _ref5) {
  var _structure$cookies3, _gaSortFallback2, _structure$route6, _structure$route7, _structure$route8, _structure$route9, _structure$route10;
  var eventCategory = _ref5.eventCategory,
    structure = _ref5.structure;
  var _ref4$list = _ref4.list,
    list = _ref4$list === void 0 ? '' : _ref4$list,
    filters = _ref4.filters,
    listPosition = _ref4.listPosition,
    origin = _ref4.origin,
    display = _ref4.display,
    data = _objectWithoutProperties(_ref4, _excluded3);
  return {
    ecommerce: {
      click: {
        actionField: {
          list: list || undefined
        },
        products: [buildProductImpression(_extends({}, data, {
          display: display,
          filters: filters,
          list: slug(list),
          listPosition: listPosition,
          location: locationToString(structure == null ? void 0 : (_structure$cookies3 = structure.cookies) == null ? void 0 : _structure$cookies3.location),
          orderBy: slug(((_gaSortFallback2 = gaSortFallback({
            orientation: structure == null ? void 0 : (_structure$route6 = structure.route) == null ? void 0 : _structure$route6.sortOrientation,
            type: structure == null ? void 0 : (_structure$route7 = structure.route) == null ? void 0 : _structure$route7.sortType
          })) == null ? void 0 : _gaSortFallback2.label) || '') || undefined,
          origin: origin,
          page: (structure == null ? void 0 : (_structure$route8 = structure.route) == null ? void 0 : _structure$route8.page) ? "page-".concat(structure == null ? void 0 : (_structure$route9 = structure.route) == null ? void 0 : _structure$route9.page) : 'page-1',
          term: structure == null ? void 0 : (_structure$route10 = structure.route) == null ? void 0 : _structure$route10.term,
          title: slug(data == null ? void 0 : data.title)
        }))]
      }
    },
    event: 'productListClick',
    eventAction: 'product-click',
    eventCategory: eventCategory,
    eventLabel: "product-list:".concat(data.variationId || data.id),
    eventNonInteraction: '0'
  };
};
export var productListClick = function productListClick() {
  var _args$structure, _args$structure2, _args$structure3;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildProductListClick(data, _extends({
    eventCategory: "".concat(args == null ? void 0 : (_args$structure = args.structure) == null ? void 0 : _args$structure.name, ":").concat(getSearchTerm(args == null ? void 0 : (_args$structure2 = args.structure) == null ? void 0 : _args$structure2.id, data, args == null ? void 0 : (_args$structure3 = args.structure) == null ? void 0 : _args$structure3.route))
  }, args));
};
export var productListShowFullList = function productListShowFullList() {
  var _args$structure4;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat((args == null ? void 0 : (_args$structure4 = args.structure) == null ? void 0 : _args$structure4.name) || '', ":veja-a-lista-completa"),
    eventLabel: data.eventLabel || '',
    eventNonInteraction: '0'
  };
};
export var productListShowMore = function productListShowMore() {
  var _args$structure5;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "".concat((args == null ? void 0 : (_args$structure5 = args.structure) == null ? void 0 : _args$structure5.name) || '', ":mostrar-mais-produtos"),
    eventLabel: data.eventLabel || '',
    eventNonInteraction: '0'
  };
};
export var productListView = function productListView() {
  var _args$structure6, _args$structure7, _args$structure8;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildProductListView(data, _extends({
    eventCategory: "".concat(args == null ? void 0 : (_args$structure6 = args.structure) == null ? void 0 : _args$structure6.name, ":").concat(getSearchTerm(args == null ? void 0 : (_args$structure7 = args.structure) == null ? void 0 : _args$structure7.id, data, args == null ? void 0 : (_args$structure8 = args.structure) == null ? void 0 : _args$structure8.route))
  }, args));
};
var buildProductItem = function buildProductItem() {
  var _structure$cookies4, _gaSortFallback3, _structure$route11, _structure$route12, _structure$route13;
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    eventCategory = _ref7.eventCategory,
    structure = _ref7.structure;
  var product = _ref6.product,
    _ref6$list = _ref6.list,
    list = _ref6$list === void 0 ? '' : _ref6$list,
    filters = _ref6.filters,
    listPosition = _ref6.listPosition,
    origin = _ref6.origin,
    display = _ref6.display,
    data = _objectWithoutProperties(_ref6, _excluded4);
  return {
    ecommerce: {
      impressions: [_extends({}, buildProductImpression(_extends({}, data, product, {
        display: display,
        filters: filters,
        list: slug(list),
        listPosition: listPosition,
        location: locationToString(structure == null ? void 0 : (_structure$cookies4 = structure.cookies) == null ? void 0 : _structure$cookies4.location),
        orderBy: slug(((_gaSortFallback3 = gaSortFallback({
          orientation: structure == null ? void 0 : (_structure$route11 = structure.route) == null ? void 0 : _structure$route11.sortOrientation,
          type: structure == null ? void 0 : (_structure$route12 = structure.route) == null ? void 0 : _structure$route12.sortType
        })) == null ? void 0 : _gaSortFallback3.label) || '') || undefined,
        origin: origin,
        term: structure == null ? void 0 : (_structure$route13 = structure.route) == null ? void 0 : _structure$route13.term,
        title: slug(product == null ? void 0 : product.title)
      })))]
    },
    event: 'productListView',
    eventAction: 'impression',
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventNonInteraction: '1'
  };
};
export var productView = function productView() {
  var _args$structure9, _args$structure10, _args$structure11;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return buildProductItem(data, _extends({
    eventCategory: "".concat(args == null ? void 0 : (_args$structure9 = args.structure) == null ? void 0 : _args$structure9.name, ":").concat(getSearchTerm(args == null ? void 0 : (_args$structure10 = args.structure) == null ? void 0 : _args$structure10.id, data, args == null ? void 0 : (_args$structure11 = args.structure) == null ? void 0 : _args$structure11.route))
  }, args));
};
export var startExperiment = function startExperiment() {
  var _args$structure12, _data$abTesting, _data$abTesting2, _data$abTesting3, _data$abTesting4;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    event: 'Search_startExperiment',
    experimentChannel: args == null ? void 0 : (_args$structure12 = args.structure) == null ? void 0 : _args$structure12.device,
    experimentID: data == null ? void 0 : (_data$abTesting = data.abTesting) == null ? void 0 : _data$abTesting.id,
    experimentName: data == null ? void 0 : (_data$abTesting2 = data.abTesting) == null ? void 0 : _data$abTesting2.name,
    experimentVariantID: data == null ? void 0 : (_data$abTesting3 = data.abTesting) == null ? void 0 : _data$abTesting3.variantId,
    experimentVariantName: data == null ? void 0 : (_data$abTesting4 = data.abTesting) == null ? void 0 : _data$abTesting4.variantName
  };
};
export var variationClick = function variationClick() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "busca",
    eventLabel: "imagens-variacoes:".concat(data == null ? void 0 : data.type, ":").concat(data == null ? void 0 : data.value) || '',
    eventNonInteraction: '0'
  };
};
var reviewFilterClick = function reviewFilterClick(type, _ref) {
  var data = _ref.data,
    structure = _ref.structure;
  if (structure.config.isMiniApp) {
    return {
      event: 'clickEvent',
      eventAction: 'click',
      eventCategory: 'avaliacao:geral',
      eventLabel: "".concat(type, ":").concat(data.product.variationId),
      mini_app_name: 'avaliacao'
    };
  }
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'avaliacao:geral',
    eventLabel: "".concat(type, ":").concat(data.product.variationId)
  };
};
export default reviewFilterClick;
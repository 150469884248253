import _extends from "@babel/runtime/helpers/esm/extends";
var channelStoreDimensions = function channelStoreDimensions(channel) {
  var _channel$data;
  var store = channel == null ? void 0 : (_channel$data = channel.data) == null ? void 0 : _channel$data.find(function (item) {
    return item.name === 'store';
  });
  var hasStore = !!(store == null ? void 0 : store.value);
  return _extends({}, hasStore ? {
    idDaLoja: store.value.id,
    nomeDaLoja: store.value.name
  } : {});
};
export default channelStoreDimensions;
var selectPromotion = function selectPromotion(_ref) {
  var _ref$index = _ref.index,
    index = _ref$index === void 0 ? null : _ref$index,
    _ref$text = _ref.text,
    text = _ref$text === void 0 ? null : _ref$text;
  var formattedText = text ? text == null ? void 0 : text.trim().toLowerCase().replace(/\s+/g, '-') : null;
  return {
    content_type: 'hotlink',
    creative_name: 'home:magalu:superhotlinks',
    creative_slot: "slot-".concat(index),
    event: 'select_promotion',
    location_id: "home:".concat(formattedText, ":").concat(index),
    screenName: 'home'
  };
};
export default selectPromotion;
import { slug, magaluCompanies } from '@magalu/mixer-utils';
export var ga4ParseDimensions = function ga4ParseDimensions(data) {
  var _data$shippingError, _data$seller, _data$seller$details, _data$seller$details$, _data$rating, _data$rating$count, _data$rating2, _data$rating2$score, _data$offerTags, _data$badges, _data$badges$, _data$geoLocTags, _data$query;
  var reason = (data == null ? void 0 : (_data$shippingError = data.shippingError) == null ? void 0 : _data$shippingError.includes('Estoque')) ? 'sem-estoque' : 'cep-indisponivel';
  return {
    dimension5: data.seller.category,
    dimension7: undefined,
    dimension11: data.shippingError ? "falso:".concat(reason) : 'verdadeiro',
    dimension23: data.seller.category === '1p' || !!magaluCompanies[data.seller.id] ? undefined : "".concat(Number(!!(data == null ? void 0 : data.togglePdpSellerScore)), ":").concat((data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : (_data$seller$details = _data$seller.details) == null ? void 0 : (_data$seller$details$ = _data$seller$details.score) == null ? void 0 : _data$seller$details$.toString()) || null),
    dimension24: "qtd-av:".concat(data == null ? void 0 : (_data$rating = data.rating) == null ? void 0 : (_data$rating$count = _data$rating.count) == null ? void 0 : _data$rating$count.toString(), "|av:").concat(data == null ? void 0 : (_data$rating2 = data.rating) == null ? void 0 : (_data$rating2$score = _data$rating2.score) == null ? void 0 : _data$rating2$score.toString()),
    dimension41: "etiqueta:".concat((data == null ? void 0 : (_data$offerTags = data.offerTags) == null ? void 0 : _data$offerTags.includes('magalu_indica')) ? '1:magalu-indica' : '0'),
    dimension61: data.seller.id,
    dimension62: data.seller.deliveryId || undefined,
    dimension86: "selo:".concat((data == null ? void 0 : (_data$badges = data.badges) == null ? void 0 : (_data$badges$ = _data$badges[0]) == null ? void 0 : _data$badges$.text) || 'sem-selo', "|tags:").concat(slug(data == null ? void 0 : (_data$geoLocTags = data.geoLocTags) == null ? void 0 : _data$geoLocTags.title) || 'sem-tag'),
    dimension103: (data == null ? void 0 : (_data$query = data.query) == null ? void 0 : _data$query.chatbot_lu) ? 'chatbot-lu' : undefined,
    dimension104: undefined,
    dimension112: undefined,
    dimension115: undefined
  };
};
var createPromotionBanner = function createPromotionBanner(eventType) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    content_type: data == null ? void 0 : data.contentType,
    creative_name: "".concat(data == null ? void 0 : data.page, ":magalu:").concat(data == null ? void 0 : data.contentType),
    creative_slot: "".concat(data == null ? void 0 : data.position, ":").concat(data == null ? void 0 : data.slot),
    dimension4: data == null ? void 0 : data.campaignId,
    event: eventType,
    location_id: "size_type:".concat(data == null ? void 0 : data.page, ":banner").concat((data == null ? void 0 : data.contentType) === 'banner_list' ? ":".concat((data == null ? void 0 : data.index) + 1) : ''),
    motorBanner: 'topsort',
    screenName: data == null ? void 0 : data.screenName
  };
};
export var selectPromotionBanner = function selectPromotionBanner() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return createPromotionBanner('select_promotion', data);
};
export var viewPromotionBanner = function viewPromotionBanner() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return createPromotionBanner('view_promotion', data);
};
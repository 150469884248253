import { isInternationalPrice } from '@magalu/mixer-utils';
export var buildDimension8 = function buildDimension8(data) {
  var _data$product, _previousInstallment, _previousInstallment2, _previousInstallment3, _data$product2, _data$product2$instal, _data$installment, _data$product3, _data$product3$instal, _data$installment2, _data$product4, _data$product4$instal, _data$installment3;
  if ((data == null ? void 0 : data.pageName) === 'buybox') {
    return "cartao-luiza:null:null:null|cartao-credito:null:null:null";
  }
  var paymentMethods = (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.paymentMethods) || (data == null ? void 0 : data.paymentMethods);
  var luizaOuroMethod = paymentMethods == null ? void 0 : paymentMethods.find(function (method) {
    return method.id === 'luiza_ouro';
  });
  var installmentPlans = (luizaOuroMethod == null ? void 0 : luizaOuroMethod.installmentPlans) || [];
  var previousInstallment = null;
  installmentPlans.find(function (currentInstallment) {
    if (currentInstallment.interest !== '0.00') {
      return true;
    }
    previousInstallment = currentInstallment;
    return false;
  });
  var luizaCardTotalAmount = ((_previousInstallment = previousInstallment) == null ? void 0 : _previousInstallment.totalAmount) || null;
  var luizaCardInstallmentLength = ((_previousInstallment2 = previousInstallment) == null ? void 0 : _previousInstallment2.installment) || null;
  var luizaCardLastInstallmentAmount = ((_previousInstallment3 = previousInstallment) == null ? void 0 : _previousInstallment3.installmentAmount) || null;
  var creditCardTotalAmount = (data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$instal = _data$product2.installment) == null ? void 0 : _data$product2$instal.totalAmount) || (data == null ? void 0 : (_data$installment = data.installment) == null ? void 0 : _data$installment.totalAmount) || null;
  var creditCardQuantity = (data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$instal = _data$product3.installment) == null ? void 0 : _data$product3$instal.quantity) || (data == null ? void 0 : (_data$installment2 = data.installment) == null ? void 0 : _data$installment2.quantity) || null;
  var creditCardAmount = (data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : (_data$product4$instal = _data$product4.installment) == null ? void 0 : _data$product4$instal.amount) || (data == null ? void 0 : (_data$installment3 = data.installment) == null ? void 0 : _data$installment3.amount) || null;
  return "cartao-luiza:".concat(luizaCardTotalAmount, ":").concat(luizaCardInstallmentLength, ":").concat(luizaCardLastInstallmentAmount, "|cartao-credito:").concat(creditCardTotalAmount, ":").concat(creditCardQuantity, ":").concat(creditCardAmount);
};
var formatValue = function formatValue(value) {
  return value ? parseFloat(value).toFixed(2) : 'nao-calculado';
};
export var buildDimension9 = function buildDimension9(product) {
  var _product$tax, _product$tax2;
  if (!isInternationalPrice(product)) {
    return null;
  }
  var icms = formatValue(product == null ? void 0 : (_product$tax = product.tax) == null ? void 0 : _product$tax.icms);
  var importTax = formatValue(product == null ? void 0 : (_product$tax2 = product.tax) == null ? void 0 : _product$tax2.importValue);
  return "imposto:".concat(importTax, "|icms:").concat(icms);
};
import _extends from "@babel/runtime/helpers/esm/extends";
import channelStoreDimensions from '../utils/channelStoreDimensions';
export var eventCategory = "barra-localizacao";
var buildPopupZipCodeBarImpression = function buildPopupZipCodeBarImpression(_ref) {
  var data = _ref.data,
    eventLabel = _ref.eventLabel;
  return _extends({
    event: 'tooltipView',
    eventAction: 'impression',
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventNonInteraction: '1'
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
var buildPopupZipCodeBarClick = function buildPopupZipCodeBarClick(_ref2) {
  var data = _ref2.data,
    eventLabel = _ref2.eventLabel;
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventNonInteraction: '0'
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
var buildZipCodeBarClick = function buildZipCodeBarClick(_ref3) {
  var data = _ref3.data;
  return _extends({
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: eventCategory,
    eventLabel: 'ver-ofertas-para-minha-regiao',
    eventNonInteraction: '0'
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
var buildZipCodeBarResponseError = function buildZipCodeBarResponseError(error) {
  return {
    event: 'clickEvent',
    eventAction: 'erro',
    eventCategory: eventCategory,
    eventLabel: error == null ? void 0 : error.message,
    eventNonInteraction: '0'
  };
};
var buildZipCodeBarResponseSuccess = function buildZipCodeBarResponseSuccess(_ref4) {
  var data = _ref4.data;
  return _extends({
    event: 'clickEvent',
    eventAction: 'calcular',
    eventCategory: eventCategory,
    eventLabel: 'sucesso',
    eventNonInteraction: '1'
  }, (data == null ? void 0 : data.channel) && _extends({}, channelStoreDimensions(data.channel)));
};
export var zipCodeBarClick = function zipCodeBarClick(data, args) {
  return buildZipCodeBarClick(_extends({}, data, args));
};
export var zipCodeBarResponseError = function zipCodeBarResponseError(data) {
  return buildZipCodeBarResponseError(data);
};
export var zipCodeBarResponseSuccess = function zipCodeBarResponseSuccess(data, args) {
  return buildZipCodeBarResponseSuccess(_extends({}, data, args));
};
export var zipCodeBarTooltipClick = function zipCodeBarTooltipClick(data, args) {
  return buildPopupZipCodeBarClick(_extends({}, data, args, {
    eventLabel: 'preencher'
  }));
};
export var zipCodeBarTooltipClose = function zipCodeBarTooltipClose(data, args) {
  return buildPopupZipCodeBarClick(_extends({}, data, args, {
    eventLabel: 'fechar'
  }));
};
export var zipCodeBarTooltipSuccessView = function zipCodeBarTooltipSuccessView(data, args) {
  return buildPopupZipCodeBarImpression(_extends({}, data, args, {
    eventLabel: 'tooltip:preencheu-localizacao'
  }));
};
export var zipCodeBarTooltipView = function zipCodeBarTooltipView(data, args) {
  return buildPopupZipCodeBarImpression(_extends({}, data, args, {
    eventLabel: 'tooltip:compartilhe-sua-localizacao'
  }));
};
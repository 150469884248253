var contentType = 'busca';
export var didYouMeanSelect = function didYouMeanSelect(_ref) {
  var raw = _ref.raw,
    refined = _ref.refined;
  return {
    content_id: refined,
    content_type: contentType,
    event: 'select_content',
    raw: raw
  };
};
export var didYouMeanView = function didYouMeanView(_ref2) {
  var raw = _ref2.raw,
    refined = _ref2.refined;
  return {
    content_id: refined,
    content_type: contentType,
    event: 'view_content',
    raw: raw
  };
};
import _extends from "@babel/runtime/helpers/esm/extends";
import { hasPickupStore } from '@magalu/mixer-utils';
import gaBoolean from './gaBoolean';
var buildClickCommonEvent = function buildClickCommonEvent(data) {
  var _data$shipping, _data$shipping$, _data$shipping$$packa, _data$category, _data$seller, _data$seller2, _data$seller3, _data$subcategory, _data$seller4, _data$price;
  var withFreight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var shippingPackage = (data == null ? void 0 : (_data$shipping = data.shipping) == null ? void 0 : (_data$shipping$ = _data$shipping[0]) == null ? void 0 : (_data$shipping$$packa = _data$shipping$.packages) == null ? void 0 : _data$shipping$$packa[0]) || {};
  return _extends({
    categorias: data == null ? void 0 : (_data$category = data.category) == null ? void 0 : _data$category.id,
    categoriaSeller: data == null ? void 0 : (_data$seller = data.seller) == null ? void 0 : _data$seller.category,
    event: 'clickEvent',
    nomeSeller: data == null ? void 0 : (_data$seller2 = data.seller) == null ? void 0 : _data$seller2.id,
    produtoClube: gaBoolean((_data$seller3 = data.seller) == null ? void 0 : _data$seller3.discounts),
    produtoDisponivel: gaBoolean(data == null ? void 0 : data.available),
    produtoFavoritado: (data == null ? void 0 : data.accountId) ? gaBoolean(data == null ? void 0 : data.isOnWishlist) : undefined,
    sku: data == null ? void 0 : data.id,
    subcategorias: data == null ? void 0 : (_data$subcategory = data.subcategory) == null ? void 0 : _data$subcategory.id
  }, withFreight && {
    freteTotal: shippingPackage == null ? void 0 : shippingPackage.price
  }, withFreight && {
    valorFrete: shippingPackage == null ? void 0 : shippingPackage.price
  }, withFreight && {
    sellerEntrega: data == null ? void 0 : (_data$seller4 = data.seller) == null ? void 0 : _data$seller4.deliveryId
  }, withFreight && {
    retiraLoja: gaBoolean(hasPickupStore(shippingPackage == null ? void 0 : shippingPackage.deliveryTypes))
  }, {
    value: (data == null ? void 0 : data.price) ? parseFloat(data == null ? void 0 : (_data$price = data.price) == null ? void 0 : _data$price.fullPrice) : undefined
  });
};
export default buildClickCommonEvent;
export var buildClickImpression = function buildClickImpression() {
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: 'home:login',
    eventLabel: 'entrar',
    eventNonInteraction: 0
  };
};
export var signInClick = function signInClick(action, config) {
  var _config$structure;
  if ((action == null ? void 0 : action.type) === 'login' && (config == null ? void 0 : (_config$structure = config.structure) == null ? void 0 : _config$structure.name) === 'home') {
    return buildClickImpression();
  }
  return {};
};
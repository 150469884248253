import _extends from "@babel/runtime/helpers/esm/extends";
import { slug } from '@magalu/mixer-utils';
var buildEvent = {
  event: 'clickEvent',
  eventAction: 'click',
  eventCategory: 'menu',
  eventNonInteraction: '0'
};
var handleContact = function handleContact(label) {
  return label === 'compre-pelo-chat' ? 'chat' : 'telefone';
};
var clickHeaderEvent = function clickHeaderEvent(structure, label) {
  var _ref = (structure == null ? void 0 : structure.cookies) || {},
    accountData = _ref.accountData;
  var accountStatus = accountData ? 'logado' : 'deslogado';
  var headerLabel = accountData ? 'meu-espaco' : 'entre-ou-cadastre-se';
  var termLabel = label ? "".concat(accountStatus, ":").concat(slug(label)) : headerLabel;
  return _extends({}, buildEvent, {
    eventLabel: termLabel
  });
};
var clickEvent = function clickEvent(positionLabel, label) {
  return _extends({}, buildEvent, {
    eventLabel: "".concat(positionLabel || '').concat(positionLabel === 'contato:' ? handleContact(slug(label)) : slug(label))
  });
};
export var menuClick = function menuClick(_ref2) {
  var positionLabel = _ref2.positionLabel,
    label = _ref2.label;
  return clickEvent(positionLabel, label);
};
export var menuHeaderClick = function menuHeaderClick(_ref3) {
  var structure = _ref3.structure,
    label = _ref3.label;
  return clickHeaderEvent(structure, label);
};
import { slug } from '@magalu/mixer-utils';
var formatLocation = function formatLocation(location) {
  if ((location == null ? void 0 : location.zipCodeCity) && (location == null ? void 0 : location.zipCodeState)) {
    return "cep:".concat(slug(location.zipCode), ":").concat(slug(location.zipCodeCity), ":").concat(slug(location.zipCodeState));
  }
  return null;
};
var getLoginStatus = function getLoginStatus(customerId) {
  return customerId ? 'logado' : 'nao-logado';
};
var virtualPageview = function virtualPageview(_ref) {
  var _structure$cookies2, _data$product, _data$product$categor, _data$product2, _data$product2$subcat;
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure.id,
    _structure$cookies = structure.cookies,
    cookies = _structure$cookies === void 0 ? {} : _structure$cookies,
    device = structure.device;
  var cookiesLocation = cookies == null ? void 0 : cookies.location;
  var accountData = (structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.ml2_sid_c) || null;
  var customerId = (accountData == null ? void 0 : accountData.customer_id) || null;
  var clientId = (cookies == null ? void 0 : cookies.clientId) || null;
  var category = (data == null ? void 0 : (_data$product = data.product) == null ? void 0 : (_data$product$categor = _data$product.category) == null ? void 0 : _data$product$categor.id) || null;
  var subCategory = (data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : (_data$product2$subcat = _data$product2.subcategory) == null ? void 0 : _data$product2$subcat.id) || null;
  var location = {
    zipCode: cookiesLocation == null ? void 0 : cookiesLocation.zipCode,
    zipCodeCity: cookiesLocation == null ? void 0 : cookiesLocation.zipCodeCity,
    zipCodeState: cookiesLocation == null ? void 0 : cookiesLocation.zipCodeState
  };
  if (siteId === 'buybox') {
    return {
      buybox: 1,
      cepPreenchido: location.zipCode ? 1 : 0,
      cidadeuf: formatLocation(location),
      clientId: clientId,
      device: device || null,
      event: 'virtualPageview',
      idUsuario: customerId || null,
      loginAtivo: getLoginStatus(customerId),
      mundos: 'magalu',
      pageCategory: "".concat(slug(category) || null, ":").concat(slug(subCategory) || null),
      pageType: 'produto',
      screenName: 'produto',
      usuarioClube: null
    };
  }
  return null;
};
export default virtualPageview;
var viewItem = function viewItem(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var product = _ref.product,
    structure = _ref.structure;
  var userId = (structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id) || null;
  return {
    cloud_retail: {
      eventType: 'detail-page-view',
      productDetails: [{
        product: {
          id: product.variationId
        }
      }],
      userInfo: {
        userId: userId
      }
    },
    event: 'cloud_retail'
  };
};
export default viewItem;
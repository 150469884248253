import _extends from "@babel/runtime/helpers/esm/extends";
var baseMiniAppParams = function baseMiniAppParams(userId) {
  return {
    idUsuario: userId,
    loginAtivo: userId ? 'logado' : 'nao-logado'
  };
};
export var buildParamsByPath = function buildParamsByPath() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var userId = arguments.length > 1 ? arguments[1] : undefined;
  var siteId = arguments.length > 2 ? arguments[2] : undefined;
  var pathParams = {
    'product-review': {
      event: 'customScreenView',
      mini_app_name: 'avaliacao'
    },
    questions: {
      mini_app_name: 'perguntas-e-respostas',
      pageType: 'perguntas',
      screenName: 'webview:perguntas-e-respostas'
    },
    'review-list': {
      event: 'customScreenView',
      mini_app_name: 'avaliacao',
      pageType: 'avaliacao',
      screenName: 'avaliacao'
    }
  };
  var params = pathParams[id];
  if (params && siteId === 'mini-apps') {
    return _extends({}, baseMiniAppParams(userId), params);
  }
  return {};
};
var customScreenView = function customScreenView(structure) {
  var _ref = (structure == null ? void 0 : structure.cookies) || {},
    accountData = _ref.accountData;
  var userId = accountData == null ? void 0 : accountData.id;
  var customParams = buildParamsByPath(structure == null ? void 0 : structure.id, userId, structure.site);
  return _extends({
    event: 'customScreenView'
  }, customParams);
};
var buildMiniAppLoad = function buildMiniAppLoad(eventListener) {
  return function (_ref2) {
    var _ref2$structure = _ref2.structure,
      structure = _ref2$structure === void 0 ? {} : _ref2$structure;
    var pageId = structure.id,
      siteId = structure.site;
    if (pageId === 'questions' && siteId === 'mini-apps') {
      return eventListener(structure);
    }
    return null;
  };
};
export var miniAppLoad = buildMiniAppLoad(customScreenView);
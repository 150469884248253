var isSomeParametersEmpty = function isSomeParametersEmpty(data) {
  if (data === undefined || data === null) {
    return true;
  }
  var time = data.time,
    complement = data.complement,
    cost = data.cost;
  var restData = {
    complement: complement,
    cost: cost,
    time: time
  };
  var values = Object.values(restData);
  return values.every(function (value) {
    return value === undefined || value === null || value === '';
  });
};
export default isSomeParametersEmpty;
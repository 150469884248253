import _extends from "@babel/runtime/helpers/esm/extends";
import { ga4ParseDimensions } from '../utils/ga4ParseDimensions';
import { CURRENCY } from './constants';
var parseEcommerce = function parseEcommerce(data) {
  return {
    ecommerce: {
      detail: {
        products: [_extends({
          brand: data.brand.label,
          category: "".concat(data.category.id, "/").concat(data.subcategory.id),
          id: data.variationId,
          name: data.title.replace(/\s/g, '-'),
          price: data.price.price,
          quantity: '1',
          variant: data.variationId
        }, ga4ParseDimensions(data))]
      }
    }
  };
};
var viewItem = function viewItem(_ref) {
  var product = _ref.product,
    shippingError = _ref.shippingError,
    structure = _ref.structure;
  var pageName = structure.name,
    _structure$cookies = structure.cookies;
  _structure$cookies = _structure$cookies === void 0 ? {} : _structure$cookies;
  var togglePdpSellerScore = _structure$cookies.togglePdpSellerScore,
    query = structure.query;
  var ecommerceData = _extends({}, product, {
    query: query,
    shippingError: shippingError
  });
  if (pageName === 'product' || product) {
    ecommerceData.togglePdpSellerScore = togglePdpSellerScore;
  } else {
    return null;
  }
  return _extends({
    currency: CURRENCY,
    event: 'detail',
    eventAction: 'page-load',
    eventCategory: 'produto:geral',
    eventLabel: 'view_detail',
    screenName: 'produto',
    value: product.price.fullPrice
  }, parseEcommerce(ecommerceData));
};
export default viewItem;
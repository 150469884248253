import slugfy from 'slugify';
var search = function search(_ref) {
  var _structure$cookies, _structure$cookies$ac, _structure$route, _structure$route2;
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  var eventFrom = structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.from;
  var term = structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term;
  var getSearchTermLabel = function getSearchTermLabel() {
    var eventTypes = {
      clickSuggestion: "1:".concat(slugfy(term)),
      icon: "0:".concat(slugfy(term)),
      submit: "0:".concat(slugfy(term))
    };
    return eventTypes[eventFrom] || "0:".concat(slugfy(term));
  };
  if (siteId === 'search') {
    var _data$search, _data$search$term, _data$search2, _data$search2$term, _data$search3, _data$search3$product, _data$search4, _data$search4$term;
    var didCorrectTerm = (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : (_data$search$term = _data$search.term) == null ? void 0 : _data$search$term.refined) !== (data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : (_data$search2$term = _data$search2.term) == null ? void 0 : _data$search2$term.raw);
    return {
      event: 'search',
      found: (data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : (_data$search3$product = _data$search3.products) == null ? void 0 : _data$search3$product.length) > 0 ? 'found' : 'not-found',
      loginAtivo: customerId ? 'logado' : 'nao-logado',
      raw: term || null,
      search_term: getSearchTermLabel(),
      wrong_term: "".concat(+didCorrectTerm, ":").concat(data == null ? void 0 : (_data$search4 = data.search) == null ? void 0 : (_data$search4$term = _data$search4.term) == null ? void 0 : _data$search4$term.raw)
    };
  }
  return null;
};
export default search;
import _extends from "@babel/runtime/helpers/esm/extends";
import { whereAmI, useFirebasePush } from '@magalu/mixer-utils';
var dataLayerPush = function dataLayerPush(listener) {
  return function () {
    var _ref, _structure$config;
    var _ref2 = (_ref = arguments.length - 1, _ref < 0 || arguments.length <= _ref ? undefined : arguments[_ref]) || {},
      config = _ref2.config,
      structure = _ref2.structure;
    if (!listener || whereAmI.onServer || !window.dataLayer || !document) return;
    var object = _extends({}, listener.apply(void 0, arguments), {
      hitTimestamp: new Date().getTime()
    });
    if (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.isMiniApp) {
      useFirebasePush(object);
    } else {
      window.dataLayer.push(object);
    }

    if ((config == null ? void 0 : config.environment) === 'development') {
      var listnerResult = listener.apply(void 0, arguments);
      if (listnerResult) {
        var event = listnerResult.event,
          eventCategory = listnerResult.eventCategory,
          pageType = listnerResult.pageType,
          contentType = listnerResult.content_type;
        var title = "%c[ga log] ".concat(eventCategory || pageType || contentType, " ").concat(event, "\n");
        console.groupCollapsed(title, 'color: #03A9F4');
        console.log("\uD83D\uDCE1 [dataLayer.push]", _extends({}, listener.apply(void 0, arguments)));
        console.groupEnd(title);
      }
    }
  };
};
export default dataLayerPush;
var buildEvent = function buildEvent() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$event = _ref.event,
    event = _ref$event === void 0 ? 'clickEvent' : _ref$event,
    _ref$eventAction = _ref.eventAction,
    eventAction = _ref$eventAction === void 0 ? 'click' : _ref$eventAction,
    eventCategory = _ref.eventCategory,
    eventLabel = _ref.eventLabel,
    _ref$eventNonInteract = _ref.eventNonInteraction,
    eventNonInteraction = _ref$eventNonInteract === void 0 ? 0 : _ref$eventNonInteract;
  return {
    event: event,
    eventAction: eventAction,
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventNonInteraction: eventNonInteraction
  };
};
export var sellerDetailsClickOpenModal = function sellerDetailsClickOpenModal() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    seller = _ref2.seller,
    variationId = _ref2.variationId;
  return variationId && (seller == null ? void 0 : seller.id) && buildEvent({
    eventCategory: "produto:geral",
    eventLabel: "ver-mais-produtos-da-loja-parceira:".concat(variationId, ":").concat(seller == null ? void 0 : seller.id)
  });
};
export var sellerDetailsOffersLinkClick = function sellerDetailsOffersLinkClick(_ref3) {
  var seller = _ref3.seller;
  return buildEvent({
    eventCategory: "produto:lojista:".concat(seller == null ? void 0 : seller.id),
    eventLabel: 'ver-mais-produtos-da-loja'
  });
};
export var sellerDetailsPdpLinkClick = function sellerDetailsPdpLinkClick(_ref4) {
  var seller = _ref4.seller;
  return buildEvent({
    eventCategory: "produto:".concat(seller == null ? void 0 : seller.id),
    eventLabel: 'ver-mais-sobre-a-loja'
  });
};
export var sellerDetailsRatingClick = function sellerDetailsRatingClick(_ref5) {
  var seller = _ref5.seller;
  return {
    event: 'clickEvent',
    eventAction: 'click',
    eventCategory: "produto:".concat(seller == null ? void 0 : seller.id),
    eventLabel: 'saiba-como-foi-calculada-essa-nota',
    eventNonInteraction: 0
  };
};
export var sellerScoreImpression = function sellerScoreImpression(_ref6) {
  var seller = _ref6.seller;
  var _seller$details = seller.details,
    details = _seller$details === void 0 ? {} : _seller$details;
  var deliveryRate = (details == null ? void 0 : details.deliveryRate) || {};
  var answerRate = (details == null ? void 0 : details.answerRate) || {};
  var score = null;
  var totalSales = '';
  var deliveryRateStatus = '';
  var answerRateStatus = '';
  if (details == null ? void 0 : details.score) {
    score = details.score;
    totalSales = (details == null ? void 0 : details.totalSales) ? ":".concat(details.totalSales) : '';
    deliveryRateStatus = (deliveryRate == null ? void 0 : deliveryRate.status) ? ":".concat(deliveryRate.status) : '';
    answerRateStatus = (answerRate == null ? void 0 : answerRate.status) ? ":".concat(answerRate.status) : '';
  }
  return {
    event: 'clickEvent',
    eventAction: 'impression',
    eventCategory: "produto:".concat((seller == null ? void 0 : seller.id) || null),
    eventLabel: "score:".concat(score).concat(totalSales).concat(deliveryRateStatus).concat(answerRateStatus),
    eventNonInteraction: 1
  };
};